import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Descriptions,
    Select,
    Col,
    Modal,
    List,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Divider,
    Popover
} from 'antd';
import Icon, {
    InfoCircleOutlined,
    SettingOutlined,
    ExclamationCircleOutlined,
    CopyOutlined,
    CarOutlined
} from "@ant-design/icons";
import axiosInstance from "services/axios";
import ChartToolModal from "../ChartTool/ChartToolModal";
import ReactJson from "react-json-view";
import {FaCog, FaFile, FaList, FaRedo} from "react-icons/fa";
import TagValues from "../TagValues/TagValues.js"
import HistoryModal from "../DetailsCard/HistoryModal";

const {Title} = Typography;


const AnomalyDataCard = (props) => {

    const UAID = props.UAID
    const [nearbyVisible, setNearbyVisible] = useState(false);
    const [reload, setReload] = useState(0);
    const [data, setData] = useState(null);
    const [bothChartModalOpen, setBothChartModalOpen] = useState(false);
    const [accelerationChartModalOpen, setAccelerationChartModalOpen] = useState(false);
    const [gyroChartModalOpen, setGyroChartModalOpen] = useState(false);

    const getAnomalyData = () => {
        axiosInstance.post(
            '/api/anomaly_tool/data',
            {
                UAID: UAID,
            })
            .then(res => {
                console.log(res.data)
                setData(res.data)
            })
            .catch(error => {
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error in get AnomalyInfo',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            getAnomalyData();
        } else {
            setData(null)
        }
    }, [UAID, props.reload, reload]);

    const onGenerateReport = () => {

        const notificationKey = 'report-generation';

        // Show the initial info notification
        notification['info']({
            key: notificationKey,
            message: 'Generating report for UAID...',
            description: 'This could take up to 30 seconds',
            duration: 0, // duration 0 means the notification will stay until manually closed
        });

        axiosInstance.post(
            '/api/anomaly_tool/report/generate',
            {
                UAID: UAID,
                customer: props.customer,
                anomaly_type: props.anomalyType,
            })
            .then(res => {
                // console.log(res.data)
                notification.destroy(notificationKey);
                setReload(reload + 1)
                if (res.data.status_code === 200 || res.data.status_code === 201) {
                    notification['success']({message: 'Success generating report', duration: 5});
                } else {
                    notification['error']({message: 'Failed generating report - see error details in console', duration: 5});
                }
            })
            .catch(error => {
                notification.destroy(notificationKey);
                console.log(error);
                setData(null)
                notification['error']({
                    message: 'Error',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const onGeneratePlots = () => {

        const notificationKey = 'plot-generation';

        // Show the initial info notification
        notification['info']({
            key: notificationKey,
            message: 'Generating plots for UAID...',
            description: 'This could take up to 30 seconds',
            duration: 0, // duration 0 means the notification will stay until manually closed
        });

        axiosInstance.post(
            '/api/anomaly_tool/plots/generate',
            {
                UAID: UAID,
                customer: props.customer,
                anomaly_type: props.anomalyType
            })
            .then(res => {
                notification.destroy(notificationKey);
                props.setReload(props.reload + 1)
                if (res.data.status_code === 200 || res.data.status_code === 201) {
                    notification['success']({message: 'Success generating plots - please refresh page', duration: 5});
                } else {
                    notification['error']({message: 'Failed generating plots - see error details in console', duration: 5});
                }
            })
            .catch(error => {
                console.log(error);
                notification.destroy(notificationKey);
                setData(null)
                notification['error']({
                    message: 'Error',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })
    }

    const showBothChartModal = () => {
        setBothChartModalOpen(true);
    }

    const showAccelerationChartModal = () => {
        setAccelerationChartModalOpen(true);
    }

    const showGyroChartModal = () => {
        setGyroChartModalOpen(true);
    }

    const handleVisibleChange = (newVisible) => {
        setNearbyVisible(newVisible);
    };

    const handleCopy = (text) => {
        // Logic to copy text to clipboard
        navigator.clipboard.writeText(text);
        // Optionally, provide feedback to the user that the text has been copied
    };

    return (
        <>
            <ChartToolModal
                showModal={() => setBothChartModalOpen(true)}
                hideModal={() => setBothChartModalOpen(false)}
                open={bothChartModalOpen}
                type={'b'}
                uaid={UAID}
            />
            <ChartToolModal
                showModal={() => setAccelerationChartModalOpen(true)}
                hideModal={() => setAccelerationChartModalOpen(false)}
                open={accelerationChartModalOpen}
                type={'a'}
                uaid={UAID}
            />
            <ChartToolModal
                showModal={() => setGyroChartModalOpen(true)}
                hideModal={() => setGyroChartModalOpen(false)}
                open={gyroChartModalOpen}
                type={'g'}
                uaid={UAID}
            />
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                title={'Data'}
                styles={{
                    body: {
                        padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                    }
                }}
            >
                <Row style={{}}>
                    <Descriptions bordered size={'middle'}>
                        {/*<Descriptions.Item label="Raw Blob Name">{data?.blob_name}</Descriptions.Item>*/}
                        <Descriptions.Item label="Processed Blob Created">{data?.blob_created}</Descriptions.Item>
                    </Descriptions>
                    <Button
                        style={{marginTop: 8}}
                        type="link"
                        icon={<CopyOutlined/>}
                        onClick={() => handleCopy(data?.blob_name)}
                    > Copy Blob Path
                    </Button>
                </Row>
                <Row style={{}}>
                    <Descriptions bordered size={'middle'}>
                        <Descriptions.Item label="Anomaly Created">{data?.created}</Descriptions.Item>
                        <Descriptions.Item label="Anomaly Modified">{data?.modified}</Descriptions.Item>
                    </Descriptions>
                </Row>
                {/*<Divider style={{marginTop: '8px', marginBottom: '0px'}}></Divider>*/}
                <Row style={{marginTop: 8}}>
                    <Popover
                        title={'Model Config'}
                        trigger="click"
                        placement="right"
                        content={
                            <div onClick={(e) => e.stopPropagation()}>
                                <ReactJson
                                    src={data?.model_config || {}}
                                    style={{maxHeight: "600px", overflowY: "auto"}}
                                    name={false}
                                    enableClipboard={false}
                                    collapsed={0}
                                    displayDataTypes={false}
                                />
                            </div>
                        }
                    >
                        <Button icon={<FaCog/>}>
                            {'\xa0'}Model Config
                        </Button>
                    </Popover>
                    <Button onClick={showBothChartModal}> Acceleration+Gyration</Button>
                    <Button onClick={showAccelerationChartModal}> Acceleration</Button>
                    <Button onClick={showGyroChartModal}> Gyration</Button>
                </Row>
                {/*<Divider style={{marginTop: '8px', marginBottom: '0px'}}></Divider>*/}
                {/*<Row style={{marginTop: 8}}>*/}

                {/*</Row>*/}
                {/*<Divider style={{marginTop: '8px', marginBottom: '0px'}}></Divider>*/}
                <Row style={{marginTop: 8}}>
                    <Descriptions bordered size={'middle'}>
                        <Descriptions.Item label="Report Modified">{data?.report_modified}</Descriptions.Item>
                        <Descriptions.Item
                            label="Anomaly Blob Modified">{data?.anomaly_blob_modified}</Descriptions.Item>
                    </Descriptions>
                </Row>
                <Row style={{marginTop: 8}}>
                    <Button onClick={onGeneratePlots} icon={<FaRedo/>}>
                        {'\xa0'}Generate Plots
                    </Button>
                    <Button onClick={onGenerateReport} icon={<FaRedo/>}>
                        {'\xa0'}Generate Report
                    </Button>
                    {(data?.report_url != null &&
                        <Button type="default" href={data?.report_url} target="_blank" icon={<FaFile/>}>
                            {'\xa0'}Report
                        </Button>
                    )}
                    {(data?.web_app_url != null &&
                        <Button type="default" href={data?.web_app_url} target="_blank" icon={<FaFile/>}>
                            {'\xa0'}Webapp
                        </Button>
                    )}
                </Row>
                <Divider style={{marginTop: '8px', marginBottom: 8}}></Divider>
                {(data?.nearby_list.length === 0 &&
                    <div style={{marginLeft: 6, marginTop: 8}}> No other Anomalies within 60 seconds</div>
                )}
                {(data?.nearby_list.length > 0 &&
                    <div>
                        <Popover
                            content={
                                <List
                                    itemLayout="vertical"
                                    dataSource={data.nearby_list}
                                    renderItem={(url) => (
                                        <List.Item>
                                            <Typography.Link href={url} target="_blank">{url}</Typography.Link>
                                        </List.Item>
                                    )}
                                />
                            }
                            placement={'right'}
                            title="Anomalies within 60 seconds"
                            trigger="click"
                            open={nearbyVisible}
                            onOpenChange={handleVisibleChange}
                        >
                            <Button type="primary">
                                Show nearby Anomalies
                            </Button>
                        </Popover>
                    </div>
                )}
            </Card>
        </>
    );
}

export default AnomalyDataCard;
