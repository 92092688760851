import React, {useCallback, useEffect, useState} from "react";
import {useGridFilter} from "ag-grid-react";
import {Button, Input, InputNumber, Space, Typography} from "antd";

const {Text} = Typography;

const CustomTagFilter = ({model, onModelChange}) => {
    const [closeFilter, setCloseFilter] = useState();
    const [unappliedModel, setUnappliedModel] = useState(model);

    const doesFilterPass = useCallback((params) => {
        const filters = unappliedModel || {};
        const data = params.data.matching_analysis || [];

        return Object.entries(filters).every(([type, {filter}]) => {
            if (type === 'tagCount') {
                return data.length >= filter;
            } else if (type === 'tagSum') {
                const currentTagSum = data.reduce((sum, tag) => sum + tag.value, 0);
                return currentTagSum >= filter;
            } else if (type === 'tagContent') {
                return data.some(tag => tag.content.toLowerCase().includes(filter.toLowerCase()));
            }
            return true;
        });
    }, [unappliedModel]);

    const afterGuiAttached = useCallback(({hidePopup}) => {
        setCloseFilter(() => hidePopup);
    }, []);

    useGridFilter({
        doesFilterPass,
        afterGuiAttached,
    });

    useEffect(() => {
        setUnappliedModel(model);
    }, [model]);

    useEffect(() => {
        onModelChange(unappliedModel);
    }, [unappliedModel]);

    const onFilterChange = (type, filter) => {
        setUnappliedModel(prevState => {
            const newState = {...prevState};

            if (filter !== null && filter !== '') {
                newState.type = type;
                newState.filter = filter;
            } else {
                // Remove both type and filter if filter is null
                delete newState.type;
                delete newState.filter;
            }

            return newState;
        });
    };

    const onClick = () => {
        if (closeFilter) {
            closeFilter();
        }
    };

    return (
        <div className="custom-tag-filter" style={{padding: 8, width: 200}}>
            <Space direction="vertical" size="middle" style={{width: '100%'}}>
                <div>
                    <Text strong>Filter Tag Count (minimum)</Text>
                    <InputNumber
                        min={1}
                        value={unappliedModel?.type === 'tagCount' ? unappliedModel?.filter : ''}
                        onChange={(value) => onFilterChange('tagCount', value)}
                        style={{width: '100%'}}
                        disabled={unappliedModel?.type && unappliedModel?.type !== 'tagCount' ? true : false}
                    />
                </div>
                <div>
                    <Text strong>Filter Tag Sum (minimum)</Text>
                    <InputNumber
                        min={-10}
                        max={10}
                        value={unappliedModel?.type === 'tagSum' ? unappliedModel?.filter : ''}
                        onChange={(value) => onFilterChange('tagSum', value)}
                        style={{width: '100%'}}
                        disabled={unappliedModel?.type && unappliedModel?.type !== 'tagSum' ? true : false}
                    />
                </div>
                <div>
                    <Text strong>Filter Tag Contains</Text>
                    <Input
                        value={unappliedModel?.type === 'tagContent' ? unappliedModel?.filter : ''}
                        onChange={(e) => onFilterChange('tagContent', e.target.value)}
                        style={{width: '100%'}}
                        disabled={unappliedModel?.type && unappliedModel?.type !== 'tagContent' ? true : false}
                    />
                </div>
                <Button type="primary" onClick={onClick} block>
                    Close
                </Button>
            </Space>
        </div>
    );
};

export default CustomTagFilter;
