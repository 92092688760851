import React, {useEffect, useState} from 'react';
import {Modal, Button, notification, Typography, Checkbox} from 'antd';
import {Form, Input, Select, DatePicker} from 'antd';
import axiosInstance from "services/axios";
import moment from 'moment';

const {Text} = Typography;
const {Option} = Select;

const dateFormat = "YYYY-MM-DD";

const MatchingScoreModal = (props) => {

        const [startDate, setStartDate] = useState(null);
        const [endDate, setEndDate] = useState(null);

        const [form] = Form.useForm()
        const pattern = /^[0-9]*$/;

        const [checkboxValue, setCheckboxValue] = useState(true); // State to hold checkbox value

        const handleCheckboxChange = (e) => {
            setCheckboxValue(e.target.checked);
        };

        const handleCancel = () => {
            props.hideModal && props.hideModal();
        };

        const onFinish = (data) => {
            let payload = Object.assign({}, data);
            Object.keys(payload).forEach(key => payload[key] === undefined ? delete payload[key] : {});

            payload.date_start = startDate
            payload.date_end = endDate

            console.log('Send request to create event with data', payload)
            axiosInstance.post('/api/matching_tool/matching_score_batch', payload)
                .then(res => {
                    console.log(res)
                    const message = res.data.message
                    if (message.includes("WARNING")) {
                        notification['warning']({
                                message: res.data.message,
                                duration: 5,
                                maxCount: 15
                            }
                        );
                    } else {
                        notification['success']({
                                message: res.data.message,
                                duration: 5,
                                maxCount: 15
                            }
                        );
                    }

                    form.resetFields();
                    setStartDate(null)
                    setEndDate(null)
                    setCheckboxValue(true)
                    props.hideModal && props.hideModal();

                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error calculating matching score',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                        duration: 10
                    });
                })
        };

        const onFinishFailed = (errorInfo) => {
            setStartDate(null)
            setEndDate(null)
            console.log('Failed:', errorInfo);
        };

        return (
            <>
                <Modal title="Run MatchingScore calculations"
                       open={props.visible}
                       onCancel={handleCancel}
                       getContainer={false}
                       forceRender={true}
                       width={"600px"}
                       footer={[
                           <Button key="cancel" onClick={handleCancel}>
                               Cancel
                           </Button>,
                           <Button
                               type="primary"
                               form="formMatchingScore"
                               key="submit"
                               htmlType="submit">
                               Submit
                           </Button>,
                       ]}
                >
                    <Form
                        name="basic"
                        id="formMatchingScore"
                        form={form}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 16}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        {/*<Text style={{fontSize: 12}}> *detected events are automatically added to the matching tool*/}
                        {/*    database </Text>*/}
                        <Form.Item
                            style={{marginTop: 16}}
                            label="Box"
                            name="box_id"
                            required={true}
                            rules={[
                                {
                                    message: 'Please enter a number',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || pattern.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Please enter a number'));
                                    },
                                }),
                            ]}
                        >
                            <Input style={{width: 158}} placeholder="123456"/>
                        </Form.Item>
                        <Form.Item
                            label="Start Date"
                            name="date_start"
                            required={true}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(currentDate) =>
                                    currentDate && (currentDate > moment().endOf('day') ||
                                        (endDate && currentDate.isBefore(endDate.subtract(30, 'days')))) ||
                                    (endDate && currentDate.isAfter(endDate))
                                }
                                onChange={(selected) => {
                                    setStartDate(selected.add(12, 'hour'))
                                }}
                            />
                        </Form.Item>
                        <Form.Item
                            label="End Date"
                            name="date_end"
                            required={true}
                        >
                            <DatePicker
                                format={dateFormat}
                                disabledDate={(currentDate) =>
                                    currentDate && (currentDate > moment().endOf('day') ||
                                        (startDate && currentDate.isAfter(startDate.add(14, 'days')))) ||
                                    (startDate && currentDate.isBefore(startDate.add(-13, 'hour')))
                                }
                                onChange={(selected) => {
                                    setEndDate(selected.add(12, 'hour'))
                                }}
                            />
                        </Form.Item>
                        <div style={{marginLeft: 100, fontSize: 12, marginTop: -20, marginBottom: 20}}>
                            *maximum selection range is 14 days
                        </div>
                        <Form.Item
                            label="Event"
                            name="event_id"
                            required={true}
                        >
                            <Input placeholder="XADGDSFASD"/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        )
            ;
    }
;

export default MatchingScoreModal;