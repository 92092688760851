import React, {useEffect, useState, useCallback} from 'react';
import {debounce} from 'lodash';
import {Modal, Input, Descriptions, Select, Button, Alert, notification, Row, Col, Divider} from 'antd';
import axiosInstance from "../../services/axios";
import _ from 'lodash';
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import TagValues from "../TagValues/TagValues";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import {carPartsNumbers, carPartsNames, carPartsGerman} from "../CarPartRenderer/CarParts";

const {Option} = Select;

const InspectionModal = (props) => {

        const [inspectionId, setInspectionId] = useState(null);
        const [inspectionData, setInspectionData] = useState(null);
        const [commentValue, setCommentValue] = useState(null);

        useEffect(() => {
            setInspectionId(null)
            setCommentValue(null)
            setInspectionData(null)
        }, [props.count]);

        const handleCancel = () => {
            props.hideModal && props.hideModal();
        };

        const handleInspectionIdChange = (e) => {
            setInspectionId(e.target.value);
            setInspectionData(null);
        };

        const debouncedPostRequest = useCallback(
            _.debounce((payload) => {
                axiosInstance.post('/api/anomaly_tool/inspection/details', payload)
                    .then(res => {
                        console.log('/inspection/details', res.data.data)
                        setInspectionData(res.data.data)
                        if (res.data.data) {
                            setCommentValue(res.data.data.Comment)
                            // setCarPartIds(res.data.data.car_part_ids)
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        notification.error({
                            message: 'Error',
                            description: `${error.message} - ${error.response?.data?.message}`,
                        });
                    });
            }, 1000),
            []
        );

        useEffect(() => {
            const payload = {
                uaid: props.uaid,
                inspection_id: inspectionId,
                customer: props.customer
            };
            if (inspectionId) {
                debouncedPostRequest(payload);
            }
        }, [inspectionId, debouncedPostRequest]);

        function handleButtonClick() {
            const payload = {
                customer: props.customer,
                uaid: props.uaid,
                event_id: props.eventId,
                inspection_id: inspectionId,
                comment: commentValue,
            }
            notification['info']({message: 'Map inspection request submitted ...'});
            axiosInstance.post('/api/anomaly_tool/match/inspection', payload)
                .then(res => {
                    console.log('/anomaly_tool/match/inspection', res);
                    notification['success']({message: 'Map inspection request processed successfully'});
                    notification['success']({message: res['data']['message']});
                    props.setOpen(false)
                    if (props?.setReload) {
                        props.setReload(prevCounter => prevCounter + 1);
                    }
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error',
                        description: error.message + ' - ' + error.response.data.message
                    });
                })
        }

        const handleCommentChange = (e) => {
            setCommentValue(e.target.value);
        };


        return (
            <>
                <Modal title="Map existing Inspection to this Anomaly Event"
                       open={props.open}
                       onCancel={handleCancel}
                       width={600}
                       height={"90vh"}
                       footer={null}
                >
                    <Alert
                        description="Use this to map an inspection to this anomaly. The anomaly event must already exisit in the webapp. The inspection cannot be mapped to another event."
                        type="warning"
                        showIcon
                    />
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'UAID'}>
                            <Input
                                value={props.uaid}
                                disabled={true}
                                // suffix={'test'}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Event'}>
                            <Input
                                value={props.eventId}
                                disabled={true}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    {props.inspectionData !== null && props.inspectionData?.length > 0 &&
                        <Descriptions bordered size={'small'}>
                            <Descriptions.Item label={'Mapped Inspections'}>
                                {props.inspectionData.map(({inspection_id, inspection_datetime}) => (
                                    <div key={inspection_id}>
                                        <span>{inspection_datetime.slice(0, 16)} -</span>{' '}
                                        <a href={`/image_tool/${inspection_id}?customer=${props.customer}`}
                                           target="_blank" rel="noopener noreferrer">
                                            {inspection_id}
                                        </a>
                                    </div>
                                ))}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                    <Divider></Divider>

                    <Descriptions bordered size={'small'}>
                        <Descriptions.Item label={'Inspection'}>
                            <Input
                                value={inspectionId}
                                placeholder="please input Inspection ID"
                                onChange={handleInspectionIdChange}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                    {inspectionData !== null && inspectionData?.IE_event_id == props.eventId &&
                        <Alert
                            message={`This Inspection has already been mapped to this event ${inspectionData.IE_event_id}`}
                            description="The mapping will be performed again."
                            type="warning"
                            showIcon
                        />
                    }
                    {inspectionData !== null && inspectionData?.IE_event_id !== null && inspectionData?.IE_event_id !== props.eventId &&
                        <Alert
                            message={`This Inspection has already been mapped to another event ${inspectionData.IE_event_id}`}
                            description="To map this inspection to a different event, first remove the mapping."
                            type="error"
                            showIcon
                        />
                    }
                    {inspectionData !== null &&
                        <Descriptions bordered size={'small'}>
                            <Descriptions.Item
                                label={'inspection_datetime'}>{inspectionData.inspection_datetime}</Descriptions.Item>
                            <Descriptions.Item
                                label={'inspection_result'}>{inspectionData.inspection_result}</Descriptions.Item>
                        </Descriptions>
                    }
                    <Divider></Divider>
                    <Row>
                        <Button
                            style={{marginTop: 16}}
                            // disabled={inspectionData === null || inspectionData?.IE_event_id !== null}
                            onClick={() => handleButtonClick()}
                        >
                            Submit
                        </Button>
                    </Row>
                </Modal>
            </>
        )
            ;
    }
;

export default InspectionModal;