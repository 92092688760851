import React, {useState, useRef, useCallback, useEffect, useMemo} from 'react';
import {
    notification,
    Select,
    Table,
    Tooltip,
    Row,
    Checkbox,
    Typography,
    Menu,
    Dropdown,
    Carousel,
    Image,
    Spin,
    Flex
} from 'antd';
import axiosInstance from "../../services/axios";
import GroupImages from "./GroupImages";

const {Option} = Select;
const {Text, Paragraph, Link} = Typography;


const DamageReportTable = (props) => {

    // console.log(props)
    const [tableHeight, setTableHeight] = useState(175); // Default height
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [contextMenu, setContextMenu] = useState({visible: false, x: 0, y: 0, record: null});
    const [isLoading, setIsLoading] = useState(true);

    const currentURL = window.location.href;
    let urlBase = "https://matching.carvaloo.com"
    if (currentURL.includes("localhost")) {
        urlBase = "http://localhost:3000";
    }

    useEffect(() => {
        const screenHeight = window.screen.height;
        if (props.activeLabelTab === 'hide') {
            if (screenHeight >= 1440) {
                setTableHeight(740)
            } else {
                setTableHeight(385)
            }
        } else {
            if (screenHeight >= 1440) {
                setTableHeight(530)
            } else {
                setTableHeight(175)
            }
        }
    }, [props.activeLabelTab]);

    useEffect(() => {
        if (props.containerRef) {

            if (props.containerRef.current) {
                // Adjust height based on container’s client height minus any padding you want
                if (props.containerMax) {
                    setTableHeight(props.containerMax - props.containerRef.current.clientHeight); // Adjust 50px offset as needed
                }
                setTableHeight(props.containerRef.current.clientHeight); // Adjust 50px offset as needed
            }

            // const updateTableHeight = () => {
            //     if (props.containerRef.current) {
            //         // Adjust height based on container’s client height minus any padding you want
            //         if (props.containerMax) {
            //             setTableHeight(props.containerMax - props.containerRef.current.clientHeight); // Adjust 50px offset as needed
            //         }
            //         setTableHeight(props.containerRef.current.clientHeight); // Adjust 50px offset as needed
            //     }
            // };
            //
            // // Set up a ResizeObserver to watch for size changes in the container
            // const resizeObserver = new ResizeObserver(updateTableHeight);
            // if (props.containerRef.current) resizeObserver.observe(props.containerRef.current);
            //
            // // Cleanup observer on component unmount
            // return () => resizeObserver.disconnect();
        }
    }, [props.containerRef]);

    const filteredData = props.allDamages
        ? props.data
        : props.data.filter(record => record.new_damage_label && (record.state === 'tp' || record.state === 'fn'));

    const defaultColumns = [
        // {
        //     title: 'Label',
        //     dataIndex: 'state', // Assuming the field in your data is called 'state'
        //     key: 'state',
        //
        // },
        // {
        //     title: 'AIDetectedID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 80,
        //     render: (text, record) => renderDropdown(text, record),
        // },
        {
            title: 'Doc',
            dataIndex: 'doc_id',
            key: 'doc_id',
            width: 60,
            render: (text, record) => renderDocId(text, record),
            sorter: (a, b) => a.doc_id - b.doc_id, // Numeric comparison for integers
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            render: (state) => renderStateCircle(state), // Use the custom renderer here,
            width: 50,
        },
        {
            title: 'DAV',
            dataIndex: 'auto_validation',
            key: 'auto_validation',
            render: (text) => CheckboxRenderer(text),
            width: 50,
        },
        {
            title: 'Car Part',
            dataIndex: 'carPart_id',
            key: 'carPart_id',
            width: 140,
            render: (text, record) => renderCarPartCV(text, record),
            sorter: (a, b) => a.carPart_id.localeCompare(b.carPart_id),
        },
        {
            title: 'Typology',
            dataIndex: 'typology',
            key: 'typology',
            // width: 140,
            ellipsis: true, // This adds an ellipsis if the text overflows
            sorter: (a, b) => a.typology.localeCompare(b.typology),
            // render: (typology, record) => renderLabel(
            //     typology,
            //     record.typology,
            //     true
            // )
        },
        {
            title: 'Area',
            dataIndex: 'surface',
            key: 'surface',
            width: 80,
            sorter: (a, b) => a.surface - b.surface, // Numeric comparison for integers
            // render: (surface, record) => renderLabel(
            //     surface,
            //     record.surface_label,
            //     true
            // )
        },
        // {
        //     title: 'Severity',
        //     dataIndex: 'severity',
        //     key: 'severity',
        //     width: 80,
        //     sorter: (a, b) => a.severity.localeCompare(b.severity),
        //     // render: (severity, record) => renderLabel(
        //     //     severity,
        //     //     record.severity_label,
        //     //     true
        //     // )
        // },
        {
            title: 'New',
            dataIndex: 'new_damage',
            key: 'new_damage',
            render: (new_damage, record) => renderNewDamage(
                record.damage_popover,
                record.new_damage_label,
                record.state,
            ),
            // width: 50,
        },
        // {
        //     title: 'Other',
        //     dataIndex: 'is_other',
        //     key: 'is_other',
        //     render: (text) => CheckboxRenderer(text),
        // },
        {
            title: 'Matching',
            dataIndex: 'matching_label',
            key: 'matching_label',
            render: (text) => CheckboxRenderer(text),
            width: 80,
        },
        {
            title: 'Group',
            dataIndex: 'group_uuid',
            key: 'group_uuid',
            width: 80,
            render: (group_uuid, record) => {
                return (
                    <Tooltip placement="right"
                             title={<GroupImages groupData={props.groupData} group_uuid={group_uuid}/>}>
                        {record.group_uuid_not_saved ? (
                            <Text style={{color: 'lightgray'}}>
                                {group_uuid ? group_uuid.slice(0, 8) : null}
                            </Text>
                        ) : (
                            <Text>
                                {group_uuid ? group_uuid.slice(0, 8) : null}
                            </Text>
                        )}
                    </Tooltip>
                );
            }
        },
    ];

    const renderCarPartCV = (text, record) => {
        let updatedText = text;

        if (record.carpart?.toLowerCase().includes("bumper")) {
            if (record.carpart?.toLowerCase().includes("left")) {
                updatedText += "_left";
            } else if (record.carpart?.toLowerCase().includes("right")) {
                updatedText += "_right";
            }
        }

        return (
            <Tooltip
                placement="top" // This places the tooltip to the top
                title={record.carpart}
            >
                <span>{updatedText}</span>
            </Tooltip>
        );
    };

    const CheckboxRenderer = (value) => (
        <Checkbox checked={value} disabled/>
    );

    const docIDOptions = props.labels?.map(str => ({
        value: str,    // Assign the string to the value property
        label: str     // Assign the string to the label property
    }));


    const renderLabel = (value, label, showLabel) => {

        let borderColor;
        let backgroundColor;
        if (label === value) {
            borderColor = 'green';
            backgroundColor = 'green';
        } else if (label !== null && value !== null && label !== value) {
            borderColor = 'red';
            backgroundColor = 'red';
        }

        return (
            <Row>
                {borderColor && backgroundColor && value !== null && label !== null &&
                    <Tooltip title={label ? label : value}>
                        <div
                            style={{
                                marginTop: 6,
                                marginRight: 12,
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                border: `2px solid ${borderColor}`, // Added border style
                                borderColor: borderColor,
                                backgroundColor: backgroundColor,
                                display: 'inline-block',
                            }}
                        />
                    </Tooltip>
                }
                {value ? showLabel && value :
                    showLabel && label
                }
            </Row>
        );
    };

    const renderStateCircle = (state) => {
        let borderColor;
        let backgroundColor;
        switch (state) {
            case 'fn':
                borderColor = 'green';
                backgroundColor = 'white';
                break;
            case 'fp':
                borderColor = 'red';
                backgroundColor = 'red';
                break;
            case 'tp':
                borderColor = 'green';
                backgroundColor = 'green';
                break;
            default:
                return null; // Renders nothing if the state is not fn, fp, or tp
        }

        return (
            <Tooltip title={state}>
                <div
                    style={{
                        marginLeft: 12,
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        border: `2px solid ${borderColor}`, // Added border style
                        borderColor: borderColor,
                        backgroundColor: backgroundColor,
                        display: 'inline-block',
                    }}
                />
            </Tooltip>
        );
    };


    const renderDocId = (text, record) => {
        // Get the doc_id from the record

        const {doc_id, AI_model} = record;
        let imageIndex = null
        let imageUrl = null

        if (props.labels && props.imageData && props.imageDataAI) {
            // Find the image index using doc_id in props.labels
            imageIndex = props.labels.findIndex(label => String(label) === doc_id);

            imageUrl = imageIndex !== -1 && props.imageDataAI[imageIndex] && AI_model !== null
                ? props.imageDataAI[imageIndex]
                : null;  // Handle missing data by checking for valid image

            // Check if the image index exists and get the image URL from props.imageData
            if (imageUrl === null) {
                imageUrl = imageIndex !== -1 && props.imageData[imageIndex]
                    ? props.imageData[imageIndex]
                    : null;  // Handle missing data by checking for valid image
            }
        }


        if (record.doc_id === null && docIDOptions && docIDOptions.length > 0) {
            return (
                <Select
                    defaultValue={text || 'Set'}
                    onChange={(value) => handleSelectChange(value, record, 'doc_id')}
                    style={{width: 75}}
                >
                    {docIDOptions.map(option => (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            );
        } else {
            return (
                props.labels && props.imageData ?
                    <Tooltip
                        placement="left" // This places the tooltip to the right
                        title={
                            imageUrl
                                ? <img src={imageUrl} alt="Car Part Image" style={{width: 230}}/>
                                : "Image not available"
                        }
                    >
                        <span>{text}</span>
                    </Tooltip>
                    :
                    <span>{text}</span>
            );
        }
    }

    const renderNewDamage = (damage_popover, new_damage_label, state) => {

        const tooltipContent = damage_popover && Object.keys(damage_popover).length > 0
            ? damage_popover
            : null;
        const displayText = tooltipContent ? 'No' : 'Yes'; // Display 'Yes' or 'No'
        const newDamagePred = tooltipContent ? false : true;

        const renderTooltipContent = (content) => {
            if (!content || typeof content !== 'object') return null;

            let displayContent = content
            if ('popover_content' in content) {
                displayContent = content['popover_content']
            }

            return (
                <ul>
                    {Object.entries(displayContent).map(([key, value]) => (
                        <li key={key}>
                            {key}: <a
                            href={urlBase + `/image_tool/${encodeURIComponent(key)}?customer=${props.customer}`}
                            target="_blank" rel="noopener noreferrer">Link</a>
                        </li>
                    ))}
                </ul>
            );
        };

        return tooltipContent ? (
                <Row>
                    {state !== 'fp' && renderLabel(newDamagePred, new_damage_label, false)}
                    <Tooltip title={renderTooltipContent(tooltipContent)}>
                        <span style={{color: 'blue'}}>{displayText}</span>
                    </Tooltip>
                </Row>
            ) :
            (
                <Row>
                    {state !== 'fp' && renderLabel(newDamagePred, new_damage_label, false)}
                    <span>{displayText}</span>
                </Row>
            );
    };

    const handleSelectChange = (value, record, column) => {
        props.updateData(record.damage_uuid, record.state, column, value);
    };

    // console.log(tableHeight)

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const handleRightClick = (event, record) => {
        console.log(event)
        event.preventDefault();
        setContextMenu({
            visible: true,
            x: event.clientX,
            y: event.clientY,
            record,
        });
    };

    const hideContextMenu = () => {
        setContextMenu({...contextMenu, visible: false});
    };

    const handleMenuClick = (key) => {
        console.log(`Action ${key} on`, contextMenu.record);
        console.log(selectedRowKeys)

        if (selectedRowKeys.length > 0) {
            axiosInstance.post(
                '/api/image_tool/labels/batch_update',
                {
                    customer: props.customer,
                    search_value: props.searchValue,
                    search_type: props.searchType,
                    update_ids: selectedRowKeys,
                    update_key: key,
                })
                .then(res => {
                    notification['success']({
                        message: 'CarPart Labels updated',
                    });
                    hideContextMenu();
                    props.setUpdateCounter(prevCounter => prevCounter + 1);
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updateLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        }
    };

    const menu = (
        <Menu onClick={(e) => handleMenuClick(e.key)}>
            <Menu.Item key="matching_true">Set Matching "True" for selected rows</Menu.Item>
            <Menu.Item key="matching_false">Set Matching "False" for selected rows</Menu.Item>
            <Menu.Item key="create_group">Create new group for selected rows</Menu.Item>
        </Menu>
    );

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            disabled: record.state === null || record.state === 'fp', // Disable selection if record.state is null
        }),
    };


    let columns = defaultColumns
    if (props.selectedColumns) {
        columns = columns.filter(value => props.selectedColumns.includes(value.key))
    }

    const scroll = props.scroll || 200

    const rowStyle = (record) => {
        if (record.new_damage_label && (record.state === 'tp' || record.state === 'fn')) {
            return {backgroundColor: 'rgba(89,197,255,0.25)'}; // Light yellow shade
        }
        return {};
    };

    return (
        <div onClick={hideContextMenu} style={{overflowX: 'auto'}}>
            <Table
                rowSelection={props.rowSelectionDeactivated ? null : rowSelection}
                style={{width: '100%', maxWidth: '100vw'}}
                dataSource={filteredData}
                columns={columns}
                rowKey={(record) => `${record.key}`}
                pagination={false} // Change to true if you need pagination
                size={'small'}
                scroll={{y: tableHeight}} // Set max height to 300px with vertical scrolling
                onRow={(record) => ({
                    onContextMenu: (event) => handleRightClick(event, record),
                    onClick: () => props.setSelectedCarPart(record?.key), // Set selectedCarpart on row click
                    style: rowStyle(record), // Apply conditional row style here
                })}
            />
            {contextMenu.visible && (
                <Dropdown overlay={menu} trigger={[]} open={true}>
                    <div
                        style={{
                            position: 'absolute',
                            top: contextMenu.y - 100,
                            left: 0,
                            zIndex: 1000,
                            background: 'white',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                        }}
                    />
                </Dropdown>
            )}
        </div>
    );
};

export default DamageReportTable;