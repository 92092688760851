import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Checkbox
} from 'antd';
import axiosInstance from "../../services/axios";

import Icon, {
    VideoCameraAddOutlined
} from "@ant-design/icons";

import {FaCarCrash, FaFile, FaInfo, FaSearch} from "react-icons/fa";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const PredictionInfo = (props) => {

    const data = props.data

    const columns = [
        {
            title: 'Self Adjust ID',
            dataIndex: 'self_adjust_id',
            key: 'self_adjust_id',
        },
        {
            title: 'Feedback UUID',
            dataIndex: 'userfeedback_uuid',
            key: 'userfeedback_uuid',
        },
        {
            title: 'AI Model',
            dataIndex: 'AI_model',
            key: 'AI_model',
        },
        {
            title: 'Modified Timestamp',
            dataIndex: 'modified_timestamp',
            key: 'modified_timestamp',
            render: (value) => value?.split('T')[0] + ' ' + value?.split('T')[1]?.split('.')[0],
        },
        {
            title: '# Images',
            dataIndex: 'num_images',
            key: 'num_images',
        },
        {
            title: 'Car Part',
            dataIndex: 'carPart_id',
            key: 'carPart_id',
        },
        {
            title: 'Typology',
            dataIndex: 'typology',
            key: 'typology',
        },
        {
            title: 'Surface',
            dataIndex: 'surface',
            key: 'surface',
        },
        {
            title: 'Severity',
            dataIndex: 'severity',
            key: 'severity',
        },
        {
            title: 'New Damage',
            dataIndex: 'new_damage',
            key: 'new_damage',
        },
        {
            title: 'Doc ID',
            dataIndex: 'doc_id',
            key: 'doc_id',
        },
        {
            title: 'Other',
            dataIndex: 'is_other',
            render: (text) => CheckboxRenderer(text),
            key: 'is_other',
        }
    ];

    const CheckboxRenderer = (value) => (
        <Checkbox checked={value} disabled/>
    );

    return (
        <Popover
            content={<Table columns={columns} dataSource={data} pagination={false}/>}
            title="Details"
            trigger="click"
            placement="left"
        >
            <Button icon={<VideoCameraAddOutlined/>} style={{cursor: 'pointer', marginTop: 4, marginLeft: 8}}/>
        </Popover>
    );
};

export default PredictionInfo;