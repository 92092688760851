import React, {useEffect, useState, useRef} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Tag, Statistic, Checkbox
} from 'antd';
import {
    BorderOutlined, CaretRightOutlined,
    InfoCircleOutlined,
    PauseCircleOutlined,
    PauseOutlined,
    PlaySquareOutlined
} from '@ant-design/icons';

import {carParts} from "../../utility/car-parts"
import axiosInstance from "../../services/axios";

import RenderSVGVan from "../VehicleSVGRenderer/renderVehicleVector/RenderVan";
import RenderSVGLargeSUV from "../VehicleSVGRenderer/renderVehicleVector/RenderLargeSUV";
import ImageCarouselTooltip from "../VehicleSVGRenderer/ImageCarouselTooltip";
import GroupImages from "./GroupImages";

import {
    FaCarCrash, FaDesktop,
    FaFile,
    FaFirefoxBrowser,
    FaImage,
    FaInfo,
    FaInternetExplorer, FaLayerGroup, FaNode, FaObjectGroup, FaPage4,
    FaSearch,
    FaTable
} from "react-icons/fa";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const SearchInfo = (props) => {

    const [vehicleCategory, setVehicleCategory] = useState()
    const [damageParts, setDamageParts] = useState({})
    const [damageDetails, setDamageDetails] = useState([])
    const [previousDamages, setPreviousDamages] = useState({})
    const [damagesTableData, setDamagesTableData] = useState([])
    const [damageStats, setDamageStats] = useState(null)
    const [hoveredPart, setHoveredPart] = useState(null);
    const [debouncedHoveredPart, setDebouncedHoveredPart] = useState(null);
    // const [tooltipHovered, setTooltipHovered] = useState(false);
    const tooltipHoveredRef = useRef(false); // Ref for tooltip hover state
    const [vehicleDamageError, setVehicleDamageError] = useState(false);

    const [damageImages, setDamageImages] = useState(null); // Debounced state

    const infoData = props.infoData
    const plotData = props.plotData
    const searchValue = props.searchValue
    const eventID = props.eventID
    const UAID = props.UAID
    const customerId = props.customerId
    const searchType = props.searchType

    useEffect(() => {
        const handler = setTimeout(() => {
            if (!tooltipHoveredRef.current) { // Check ref instead of state
                setDebouncedHoveredPart(hoveredPart);
                const relevantCodes = carParts[hoveredPart]?.codes || [];
                setDamageImages(
                    Array.from(
                        new Set(
                            damagesTableData
                                .filter(row => relevantCodes.includes(row.carPart_id))
                                .flatMap(row => row.docs)
                        )
                    )
                );
            }
        }, 250);

        return () => clearTimeout(handler);
    }, [hoveredPart]);

    const tableColumns = [
        {
            title: 'Status',
            dataIndex: 'status_translated',
            key: 'status_translated',
            width: 80,
            render: (_, {status_translated, status_color}) => (
                <Tag color={status_color} key={status_translated}>
                    {status_translated}
                </Tag>
            )
        },
        {
            title: 'Carpart',
            dataIndex: 'carpart',
            key: 'carpart',
            sorter: (a, b) => a.carpart.localeCompare(b.carpart),
        },
        {
            title: 'Typology',
            dataIndex: 'damage_type_translated',
            key: 'damage_type_translated',
            sorter: (a, b) => a.damage_type_translated.localeCompare(b.damage_type_translated),
        },
        {
            title: 'Images',
            dataIndex: 'docs_nb',
            key: 'docs_nb',
            width: 80,
            render: (_, {docs, docs_nb}) => (
                <>
                    {docs !== undefined && docs.length > 0 ? (
                        <Tooltip placement="topLeft" color={"white"}
                                 title={<ImageCarouselTooltip image_list={docs}/>}>
                            <span style={{color: '#088ed7'}}>{docs_nb}</span>
                        </Tooltip>
                    ) : (
                        <div>_</div>

                    )}
                </>
            ),
        },
        {
            title: 'Source',
            dataIndex: 'source_translated',
            key: 'source_translated',
        },
        {
            title: 'Validation',
            dataIndex: 'validation',
            key: 'validation',
            render: (text) => CheckboxRenderer(text),
        },
    ]

    const groupColumns = [
        {
            title: 'Group',
            dataIndex: 'group_uuid',
            key: 'group_uuid',
            render: (group_uuid, record) => {
                return (
                    <Tooltip placement="right"
                             title={<GroupImages groupData={props.groupData} group_uuid={group_uuid}/>}>
                        <Text>
                            {group_uuid ? group_uuid.slice(0, 8) : null}
                        </Text>
                    </Tooltip>
                );
            },
            width: 100,
            // sorter: (a, b) => a.group_uuid.localeCompare(b.group_uuid),
            onCell: (record, rowIndex) => {
                // Calculate rowSpan for `group_uuid`
                const groupUuid = record.group_uuid;
                const firstOccurrenceIndex = props.groupData.findIndex(
                    (item) => item.group_uuid === groupUuid
                );

                return {
                    rowSpan: rowIndex === firstOccurrenceIndex
                        ? props.groupData.filter((item) => item.group_uuid === groupUuid).length
                        : 0,
                };
            },
        },
        {
            title: 'Carpart',
            dataIndex: 'car_part',
            key: 'car_part',
            // sorter: (a, b) => a.car_part.localeCompare(b.car_part),
        },
        // {
        //     title: 'Typology',
        //     dataIndex: 'typology',
        //     key: 'typology',
        //     sorter: (a, b) => a.typology.localeCompare(b.typology),
        // },
        {
            title: 'Matching',
            dataIndex: 'matching',
            key: 'matching',
            render: (text) => CheckboxRenderer(text),
            width: 80
        },
        {
            title: 'Doc ID',
            dataIndex: 'doc_id',
            key: 'doc_id',
            render: (_, {doc_id, uri_sas}) => (
                <>
                    <Tooltip
                        placement="topLeft" color={"white"}
                        title={<Image src={uri_sas}/>}
                    >
                        <span style={{color: '#088ed7'}}>{doc_id}</span>
                    </Tooltip>
                </>
            ),
            width: 80,
            // sorter: (a, b) => a.doc_id - b.doc_id,
        },
        {
            title: 'Source',
            dataIndex: 'source',
            key: 'source',
            width: 250,
            // sorter: (a, b) => a.source.localeCompare(b.source),
        }
    ]


    const CheckboxRenderer = (value) => (
        <Checkbox checked={value} disabled/>
    );

    function filterDamages(data) {
        // Filter new_damages by source !== 'webapp'
        const filteredNewDamages = Object.fromEntries(
            Object.entries(data?.new_damages || {}).filter(([, damage]) => damage.source !== 'webapp')
        );

        // Filter previous_damages by source !== 'webapp' (if necessary)
        const filteredPreviousDamages = Object.fromEntries(
            Object.entries(data?.previous_damages || {}).filter(([, damage]) => damage.source !== 'webapp')
        );

        // Filter table_damage_list by source !== 'webapp'
        const filteredTableDamageList = (data?.table_damage_list || []).filter(
            (damage) => damage.source !== 'webapp'
        );

        return {
            ...data,
            new_damages: filteredNewDamages,
            previous_damages: filteredPreviousDamages,
            table_damage_list: filteredTableDamageList,
        };
    }

    useEffect(() => {
        if (infoData?.vehicle_uuid && props.customer) {
            axiosInstance.post(
                'https://carvaloo-api-webapp.azurewebsites.net/api/KachelDamages',
                {
                    event_id: null,
                    inspection_id: null,
                    UVID: infoData?.vehicle_uuid,
                    full_output: true
                },
                {
                    headers: {
                        screen: 'vehicle',
                        language: 'EN',
                        isanonymous: false,
                        OrganizationId: props.customer
                    }
                })
                .then(res => {
                    console.log('KachelDamages res', res.data)
                    setVehicleDamageError(false)
                    setVehicleCategory(res.data.vehicle_category)

                    const filteredData = filterDamages(res.data.data);

                    setDamageDetails(filteredData.new_damages)
                    setPreviousDamages(filteredData.previous_damages)
                    setDamagesTableData(
                            filteredData.table_damage_list.map((item, index) => ({
                                ...item,
                                key: index + 1, // Add a counter starting from 1
                            }))
                    );
                })
                .catch(error => {
                    console.log(error);
                    setVehicleDamageError(true)
                    notification['error']({
                        message: 'Error in get vehicle damages',
                        duration: 5,
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        }
    }, [infoData, props.customer, props.labelReload])

    useEffect(() => {
        if (damagesTableData) {

            const stats = damagesTableData.reduce(
                (acc, record) => {
                    const isValidated = record.validation ? 'validated' : 'unvalidated';
                    const statusGroup = `${record.status}_${isValidated}`;
                    acc[statusGroup] = (acc[statusGroup] || 0) + 1;
                    return acc;
                },
                {}
            );
            setDamageStats(stats)
        }
    }, [damagesTableData])

    useEffect(() => {
        let cPList = {}
        Object.entries(carParts).forEach(function ([k, v]) {
            let status = []
            let status_cv2
            let carPArt_images = []
            let source = []
            let isClickable = true
            let validation = undefined
            let damage_type = undefined

            for (var c of v.codes) {
                if (Object.keys(damageDetails).includes(c)) {
                    status = ["new_damage"]  // used to handle selected / unselected parts and their color
                    status_cv2 = getCV2Status(damageDetails[c].source, damageDetails[c].validation)
                    validation = damageDetails[c].validation
                    damage_type = damageDetails[c].damage_type
                    source = damageDetails[c].source
                }
                // if (Object.keys(previousDamages).includes(c)) {
                //     status = [...status, "previous_damage"]
                //     validation = damageDetails[c].validation
                //     carPArt_images = [...carPArt_images, ...previousDamages[c].images_ai]
                // }
                if (damagesTableData.some(entry => entry.carPart_id === c && entry.status === "old")) {
                    status = [...status, "previous_damage"];
                    const oldEntry = damagesTableData.find(entry => entry.carPart_id === c && entry.status === "old");
                    if (validation === undefined) {
                        validation = oldEntry.validation ?? validation;
                    }
                    carPArt_images = [...carPArt_images, ...oldEntry.docs];
                }
            }
            cPList[k] = {
                ...v, status: status, carPartImages: carPArt_images, isClickable: false,
                validation: validation, damage_type: damage_type, source: source, status_cv2: status_cv2
            }
        })
        setDamageParts(cPList)
    }, [damageDetails, previousDamages]);

    const getCV2Status = (source, validation) => {
        if (source.includes("image_ai") || source.includes("validation")) {
            if (validation === true) {
                return "CV2_validated"
            } else if (validation === null) {
                return "CV2_unvalidated"
            }
        } else {
            return null
        }
    }

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                minHeight: 425,
            }}
            styles={{
                body: {
                    padding: '4px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
        >
            <Row>
                <Col span={24}>
                    <Descriptions bordered size={'middle'} column={1}>
                        <Descriptions.Item label="Customer">{infoData?.customer}</Descriptions.Item>
                        {/*<Descriptions.Item label="Status">{infoData?.anomaly_type}</Descriptions.Item>*/}
                        <Descriptions.Item label="Vehicle ID">
                            <a href={`https://webapp.carvaloo.com/fahrzeug/${encodeURIComponent(infoData?.vehicle_id)}?UVID=${encodeURIComponent(infoData?.vehicle_uuid)}&organization=${encodeURIComponent(customerId)}`}
                               target="_blank" rel="noopener noreferrer"
                            >
                                {infoData?.vehicle_id}
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="VTI">{infoData?.vehicle_type?.slice(0, 40)}</Descriptions.Item>
                        <Descriptions.Item
                            label="Timestamp">{infoData?.date} - {infoData?.time?.slice(0, 5)}</Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row style={{marginTop: 4}} justify="space-between">
                <div>
                    <Tooltip title={'Webapp'}>
                        <Button
                            type="default"
                            href={`https://webapp.carvaloo.com/${searchType === 'inspection' ? 'inspektion' : 'ereignisse'}/${searchType === 'inspection' ? encodeURIComponent(searchValue) : encodeURIComponent(eventID)}?organization=${encodeURIComponent(customerId)}`}
                            target="_blank" icon={<FaDesktop/>}
                        />
                    </Tooltip>
                    <Tooltip title={'Event Info'}>
                        <Popover
                            title={'Event Info'}
                            trigger="click"
                            placement="bottom"
                            content={
                                <div style={{maxWidth: 500, whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                    {props.infoData?.event_info_text}
                                </div>
                            }
                        >
                            <Button style={{marginLeft: 0}} size={'medium'} icon={<FaCarCrash/>}
                                    disabled={!props.infoData?.event_info_text}/>
                        </Popover>
                    </Tooltip>
                    <Tooltip title={'Inspection Result'}>
                        <Popover
                            title={'Inspection Result'}
                            trigger="click"
                            placement="bottom"
                            content={
                                <div style={{maxWidth: 500, whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                    {props.infoData?.inspection_result}
                                </div>
                            }
                        >
                            <Button size={'medium'} icon={<FaSearch/>} disabled={!props.infoData?.inspection_result}/>
                        </Popover>
                    </Tooltip>
                    <Tooltip title={'Anomaly Info'}>
                        {UAID &&
                            <Button type="default"
                                    href={`https://matching.carvaloo.com/anomaly_tool/${UAID}`}
                                    target="_blank" icon={<FaInfo/>}
                            />
                        }
                    </Tooltip>
                    <Tooltip title={'Anomaly Plot'}>
                        {UAID &&
                            <Popover
                                title={'Anomaly Plot'}
                                trigger="click"
                                placement="bottom"
                                content={
                                    <Image
                                        style={{height: 400}}
                                        src={plotData?.uaid_image}
                                        name={'uaid_image'}
                                    />
                                }
                            >
                                <Button size={'medium'} icon={<FaImage/>}/>
                            </Popover>
                        }
                    </Tooltip>
                    <Tooltip title={'Damages'}>
                        <Popover
                            title={'Damages Table'}
                            trigger="click"
                            placement="right"
                            content={
                                <Table
                                    pagination={false} // Change to true if you need pagination
                                    size={'small'}
                                    style={{maxWidth: 800}}
                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                    dataSource={damagesTableData}
                                    columns={tableColumns}
                                    rowKey={(record) => `${record.key}_${record.carPart_id}_${record.damage_type_translated}_${record.source}_${record.validation}_${record.validation}`}
                                />
                            }
                        >
                            <Button size={'medium'} icon={<FaTable/>}/>
                        </Popover>
                    </Tooltip>
                    <Tooltip title={'Groups'}>
                        <Popover
                            title={'Groups Overview'}
                            trigger="click"
                            placement="right"
                            content={
                                <Table
                                    pagination={false} // Change to true if you need pagination
                                    size={'small'}
                                    style={{maxWidth: 800}}
                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                    dataSource={props.groupData}
                                    columns={groupColumns}
                                    rowKey={(record) => `${record.group_uuid}_${record.doc_id}_${record.car_part}`}
                                />
                            }
                        >
                            <Button size={'medium'} icon={<FaLayerGroup/>}/>
                        </Popover>
                    </Tooltip>
                </div>
            </Row>
            {!vehicleDamageError ?
                <Row style={{marginTop: 8}}>
                    <Col span={10} style={{paddingLeft: 8}}>
                        <Tooltip placement="right"
                                 open={debouncedHoveredPart !== null || tooltipHoveredRef.current}
                                 title={
                                     <div
                                         onMouseEnter={() => (tooltipHoveredRef.current = true)}
                                         onMouseLeave={() => (tooltipHoveredRef.current = false)}
                                     >
                                         <GroupImages images={damageImages}/>
                                     </div>
                                 }
                        >
                            {vehicleCategory === "van" ? (
                                <RenderSVGVan
                                    damageParts={damageParts}
                                    customer_mainColor={'#088ed7'}
                                    previousDamageColor="#ACACAC"
                                    withOverlay={false}
                                    carvalooVersion={"carvaloo_20"}
                                    userType={"carvaloo1"}
                                    setHoveredPart={setHoveredPart}
                                />
                            ) : (
                                <RenderSVGLargeSUV
                                    damageParts={damageParts}
                                    customer_mainColor={'#088ed7'}
                                    previousDamageColor="#ACACAC"
                                    withOverlay={false}
                                    carvalooVersion={"carvaloo_20"}
                                    userType={"carvaloo1"}
                                    setHoveredPart={setHoveredPart}
                                />
                            )}
                        </Tooltip>
                    </Col>
                    <Col span={14}>
                        {damageStats &&
                            <div style={{marginTop: 8}}>
                                <Row gutter={16} style={{marginLeft: 24}}>
                                    <Col span={12}>
                                        <Row>
                                            New
                                        </Row>
                                        <Tooltip
                                            overlayInnerStyle={{width: 800}} // Adjusts the inner content's width
                                            placement={'right'}
                                            mouseEnterDelay={0.5}
                                            title={
                                                <Table
                                                    pagination={false} // Change to true if you need pagination
                                                    size={'small'}
                                                    style={{maxWidth: 800}}
                                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                                    dataSource={damagesTableData.filter(item => item.validation && item.status === "new")}
                                                    columns={tableColumns}
                                                    rowKey={(record) => `${record.key}_${record.carPart_id}_${record.damage_type_translated}_${record.source}_${record.validation}_${record.validation}`}
                                                />
                                            }
                                        >
                                            <Statistic
                                                title="Validated"
                                                value={damageStats['new_validated'] || 0}
                                                style={{marginTop: 8}}  // Reduce font size here
                                                valueStyle={{color: '#088ed7', fontWeight: 500}}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={12}>
                                        <Row>
                                            Old
                                            <Tooltip
                                                title={'The orange color shows a part which has a validated old damage and an unvalidated new damage'}>
                                                <InfoCircleOutlined
                                                    style={{color: '#088ed7', fontSize: '16px', marginLeft: '5px'}}/>
                                            </Tooltip>
                                        </Row>
                                        <Tooltip
                                            overlayInnerStyle={{width: 800}} // Adjusts the inner content's width
                                            placement={'right'}
                                            mouseEnterDelay={0.5}
                                            title={
                                                <Table
                                                    pagination={false} // Change to true if you need pagination
                                                    size={'small'}
                                                    style={{maxWidth: 800}}
                                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                                    dataSource={damagesTableData.filter(item => item.validation && item.status === "old")}
                                                    columns={tableColumns}
                                                    rowKey={(record) => `${record.key}_${record.carPart_id}_${record.damage_type_translated}_${record.source}_${record.validation}_${record.validation}`}
                                                />
                                            }
                                        >
                                            <Statistic
                                                title="Validated"
                                                value={damageStats['old_validated'] || 0}
                                                style={{marginTop: 8}}  // Reduce font size here
                                                valueStyle={{color: '#3d3d3d', fontWeight: 500}}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={16} style={{marginLeft: 24}}>
                                    <Col span={12}>
                                        <Tooltip
                                            overlayInnerStyle={{width: 800}} // Adjusts the inner content's width
                                            placement={'right'}
                                            mouseEnterDelay={0.5}
                                            title={
                                                <Table
                                                    pagination={false} // Change to true if you need pagination
                                                    size={'small'}
                                                    style={{maxWidth: 800}}
                                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                                    dataSource={damagesTableData.filter(item => !item.validation && item.status === "new")}
                                                    columns={tableColumns}
                                                    rowKey={(record) => `${record.key}_${record.carPart_id}_${record.damage_type_translated}_${record.source}_${record.validation}_${record.validation}`}
                                                />
                                            }
                                        >
                                            <Statistic
                                                title="Unvalidated"
                                                value={damageStats['new_unvalidated'] || 0}
                                                valueStyle={{color: '#83C6EB', fontWeight: 500}}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={12}>
                                        <Tooltip
                                            overlayInnerStyle={{width: 800}} // Adjusts the inner content's width
                                            placement={'right'}
                                            mouseEnterDelay={0.5}
                                            title={
                                                <Table
                                                    pagination={false} // Change to true if you need pagination
                                                    size={'small'}
                                                    style={{maxWidth: 800}}
                                                    scroll={{y: 600}} // Set max height to 300px with vertical scrolling
                                                    dataSource={damagesTableData.filter(item => !item.validation && item.status === "old")}
                                                    columns={tableColumns}
                                                    rowKey={(record) => `${record.key}_${record.carPart_id}_${record.damage_type_translated}_${record.source}_${record.validation}_${record.validation}`}
                                                />
                                            }
                                        >
                                            <Statistic
                                                title="Unvalidated"
                                                value={damageStats['old_unvalidated'] || 0}
                                                valueStyle={{color: '#ACACAC', fontWeight: 500}}
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                </Row> :
                <Alert
                    message={'Vehicle Damage query failed'}
                    type="error"
                    style={{height: 25, marginLeft: 70, marginTop: 80, width: 210}}
                />
            }
            {/*<Row style={{marginTop: 24}}>*/}
            {/*    <Col span={16}>*/}
            {/*        <div style={{*/}
            {/*            display: "flex",*/}
            {/*            // justifyContent: "center",*/}
            {/*            // alignItems: "center",*/}
            {/*        }}>*/}
            {/*            <Image*/}
            {/*                style={{height: "100px", marginLeft: 32}}*/}
            {/*                src={plotData?.uaid_image}*/}
            {/*                name={'uaid_image'}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </Col>*/}
            {/*    <Col span={8}>*/}
            {/*        <Row>*/}

            {/*            <div style={{*/}
            {/*                height: "100px",*/}
            {/*                display: "flex",*/}
            {/*                // justifyContent: "center",*/}
            {/*                // alignItems: "center",*/}
            {/*            }}>*/}
            {/*                <Image*/}
            {/*                    src={plotData?.direction_image}*/}
            {/*                    name={'direction_image'}*/}
            {/*                    style={{height: 100}}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </Row>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
        </Card>
    );
};

export default SearchInfo;