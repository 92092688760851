import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Divider,
    Form,
    Input,
    Button, Popover, Alert, Tooltip, Modal, Image, Card, Timeline, Tag, Checkbox, DatePicker
} from 'antd';
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EditFilled,
    PictureOutlined,
    WarningOutlined,
    CopyOutlined
} from "@ant-design/icons";
import {FaCarCrash, FaFile, FaInfo} from "react-icons/fa";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";
import axiosInstance from "../../services/axios";
import ImageCarouselTooltip from "../VehicleSVGRenderer/ImageCarouselTooltip";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;

const InspectionInfo = (props) => {
    const [cardHeight, setCardHeight] = useState(370);

    const inspectionData = props.inspectionData
    const labelTarget = props.labelTarget

    useEffect(() => {
        const screenHeight = window.screen.height;
        if (screenHeight >= 1440) {
            // For 2K resolution or higher
            setCardHeight(654);
        }
        // You can add additional conditions for other resolutions if needed
    }, []);

    const onFinish = (values) => {
        console.log('Sending request with:', values);
        console.log('inspectionData:', props.inspectionData);
        console.log('props.customer:', props.customer);
        console.log('InspectionInfo props:', props);
        notification['info']({message: 'Reactive event is being created ...'});
        axiosInstance.post(
            '/api/image_tool/create_event',
            {
                customer: props.customer,
                create_event: values,
                inspection_data: inspectionData,
            }
        ).then(res => {
            console.log('/api/image_tool/create_event Success:', res);
            notification['success']({
                message: 'Reactive event was created successfully',
                duration: 10,
                // maxCount: 1
            });
            notification['success']({message: res['data']['message']});
        })
            .catch(error => {
                console.log(error);
                notification['error']({message: 'Error', description: error.message});
            })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('/api/image_tool/create_event Failed:', errorInfo);
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then(() => {
                console.log('Text copied to clipboard');
            }).catch((err) => {
                console.error('Could not copy text: ', err);
            });
        }
    };

    return (
        <Card
            style={{
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                border: '1px solid #e8e8e8',
                marginBottom: 8,
                paddingBottom: 10,
                minHeight: cardHeight,
                maxHeight: cardHeight
            }}
            styles={{
                body: {
                    padding: '16px',
                    display: 'flex',
                    flexDirection: "column",
                    flex: "1 1 auto"
                }
            }}
            title={'Inspection'}
        >
            {inspectionData &&
                <div style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    minHeight: 100,
                    maxHeight: cardHeight - 86,
                }}>
                    <Row>
                        <Alert
                            message={'Reference - ' + (labelTarget ? (
                                labelTarget.length > 36 ?
                                    labelTarget.substring(0, 36) + '...' :
                                    labelTarget) : 'None')
                            }
                            type="info"
                            style={{height: 25}}
                        />
                        <CopyOutlined
                            style={{marginLeft: 8, cursor: 'pointer'}}
                            onClick={() => copyToClipboard(labelTarget)}
                        />
                    </Row>
                    <Row>
                        {
                            props.eventData?.length == 0 ? (
                                <Popover
                                    title={'Create Reactive Event'}
                                    trigger="click"
                                    placement="right"
                                    content={
                                        // <Card style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                        <Form
                                            name="basic"
                                            labelCol={{span: 5}}
                                            wrapperCol={{span: 48}}
                                            // style={{ width: 600, height: 500}}
                                            style={{width: 600}}
                                            initialValues={{remember: true}}
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label="Event ID"
                                                name="event_id"
                                                initialValue={inspectionData?.inspection_id}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Input.TextArea rows={1}/>
                                            </Form.Item>
                                            <Form.Item
                                                label="Inspection Datetime"
                                                name="inspection_datetime"
                                                // initialValue={dateObj.toISOString()}
                                                initialValue={inspectionData?.inspection_datetime}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Input
                                                    disabled={true}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Event Severity"
                                                name="severity"
                                                initialValue={2}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Select
                                                    defaultValue={2}
                                                    optionFilterProp="children"
                                                    options={[
                                                        {
                                                            label: 'High',
                                                            value: 3,
                                                        },
                                                        {
                                                            label: 'Medium',
                                                            value: 2,
                                                        },
                                                        {
                                                            label: 'Low',
                                                            value: 1,
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Damage Type"
                                                name="damage_type"
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Select
                                                    // defaultValue={2}
                                                    optionFilterProp="children"
                                                    options={[
                                                        {
                                                            'value': 'scratch',
                                                            'label': 'scratch',
                                                        },
                                                        {
                                                            'value': 'crack',
                                                            'label': 'crack',
                                                        },
                                                        {
                                                            'value': 'deformation',
                                                            'label': 'deformation',
                                                        },
                                                        {
                                                            'value': 'detached',
                                                            'label': 'detached',
                                                        },
                                                        {
                                                            'value': 'glass',
                                                            'label': 'glass',
                                                        },
                                                        {
                                                            'value': 'puncture',
                                                            'label': 'puncture',
                                                        },
                                                        {
                                                            'value': 'other',
                                                            'label': 'other',
                                                        },
                                                    ]}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Inspection Result"
                                                name="inspection_result"
                                                initialValue={inspectionData?.inspection_result}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Input.TextArea rows={2}/>
                                            </Form.Item>
                                            <Form.Item
                                                label="Last with no Damage"
                                                name="last_nodamage_datetime"
                                                style={{marginBottom: "4px"}}
                                            >
                                                <DatePicker
                                                    showTime={true}
                                                />
                                            </Form.Item>

                                            <Form.Item
                                                label="Event Info"
                                                name="event_info_text"
                                                initialValue={inspectionData?.details}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Input.TextArea rows={2}/>
                                            </Form.Item>

                                            <Form.Item
                                                label="Location"
                                                name="event_location"
                                                initialValue={''}
                                                style={{marginBottom: "4px"}}
                                            >
                                                <Input.TextArea rows={1}/>
                                            </Form.Item>

                                            <Divider style={{marginBottom: "16px", marginTop: "16px"}}/>
                                            <Form.Item label="Ticket" style={{marginBottom: "4px"}}>
                                                <Form.Item
                                                    name="ticket_form"
                                                    valuePropName="checked"
                                                    initialValue={false}
                                                    style={{marginBottom: "4px", display: 'inline-block'}}
                                                >
                                                    <Checkbox></Checkbox>
                                                </Form.Item>
                                                <Form.Item
                                                    label="Priority"
                                                    name="ticket_priority"
                                                    valuePropName="checked"
                                                    initialValue={'Medium'}
                                                    style={{
                                                        marginLeft: "10px",
                                                        marginBottom: "4px",
                                                        display: 'inline-block'
                                                    }}
                                                >
                                                    <Select
                                                        defaultValue={'Medium'}
                                                        optionFilterProp="children"
                                                        options={[
                                                            {
                                                                label: 'High',
                                                                value: 'High',
                                                            },
                                                            {
                                                                label: 'Medium',
                                                                value: 'Medium',
                                                            },
                                                            {
                                                                label: 'Low',
                                                                value: 'Low',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Form.Item>
                                            {props.eventData.length > 0 &&
                                                <Form.Item
                                                    name="alert"
                                                    valuePropName="checked"
                                                    rules={[{
                                                        required: true,
                                                        message: 'Cannot create event, already mapped event existing'
                                                    }]}
                                                    initialValue={null}
                                                >
                                                    <Text style={{color: 'red', marginLeft: "50px"}}>
                                                        Cannot create event, already mapped event existing
                                                    </Text>
                                                    <Checkbox></Checkbox>
                                                </Form.Item>
                                            }
                                            <Divider style={{marginBottom: "4px", marginTop: "4px"}}/>
                                            <Form.Item wrapperCol={{offset: 8, span: 16}}
                                                       style={{marginBottom: "4px", marginTop: "4px"}}>
                                                <Button
                                                    style={{marginBottom: "4px", marginTop: "16px"}}
                                                    type="primary"
                                                    htmlType="submit"
                                                >
                                                    Create event in carvaloo Webapp
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                        // </Card>
                                    }
                                >
                                    <Button style={{marginTop: 8, paddingTop: 4, paddingBottom: 4}}>
                                        Create Reactive Event
                                    </Button>
                                </Popover>
                            ) : (
                                <Popover
                                    title={'Mapped Event'}
                                    trigger="click"
                                    placement="right"
                                    content={
                                        <div>
                                            {props.eventData?.map(({event_id, event_datetime, alert_datetime, UAID}) => (
                                                <div key={event_id}>
                                                    <span>{event_datetime != null ? event_datetime.slice(0, 16) : alert_datetime.slice(0, 16)}</span>
                                                    <br/>
                                                    <a href={`https://webapp.carvaloo.com/ereignisse/${event_id}?organization=${props.customerId}`}
                                                       target="_blank" rel="noopener noreferrer">
                                                        {event_id}
                                                    </a>
                                                    {UAID && (<>
                                                            <br/>
                                                            <a href={`https://matching.carvaloo.com/anomaly_tool/${UAID}`}
                                                               target="_blank" rel="noopener noreferrer">
                                                                (AnomalyTool)
                                                            </a></>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                >
                                    <Tag color={'green'} style={{marginTop: 8, paddingTop: 4, paddingBottom: 4}}>
                                        <FaCarCrash/> Mapped Event
                                    </Tag>
                                </Popover>
                            )
                        }

                        {props.alertData?.length > 0 &&
                            <Popover
                                title={'Proactive Alerts (14 days prior, 12 hours after)'}
                                trigger="click"
                                placement="right"
                                content={
                                    <div>
                                        {props.alertData.map(({UAID, alert_datetime}) => (
                                            <div key={UAID}>
                                                <span>{alert_datetime.slice(0, 10)} -</span>{' '}
                                                <a href={`https://matching.carvaloo.com/anomaly_tool/${UAID}`}
                                                   target="_blank" rel="noopener noreferrer">
                                                    {UAID}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <Tag color={'red'}
                                     style={{marginLeft: 8, width: 40, marginTop: 8, paddingTop: 4, paddingBottom: 4}}>
                                    <WarningOutlined/> {props.alertData.length}
                                </Tag>
                            </Popover>
                        }
                    </Row>
                    {/*<Row style={{marginTop: 16}}>*/}
                    {/*    {inspectionData.inspection_datetime?.replace('T', ' - ').slice(0, 21)}*/}
                    {/*</Row>*/}
                    {/*<Row style={{marginTop: 20}}>*/}
                    {/*    <Text style={{fontWeight: 500, marginLeft: 0}}>*/}
                    {/*        UUID: {inspectionData.inspection_uuid}*/}
                    {/*    </Text>*/}
                    {/*</Row>*/}
                    <Divider style={{marginTop: 16, marginBottom: 16}}></Divider>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Reservation ID: {inspectionData.reservation_id || 'Not available'}
                        </Text>
                    </Row>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Car Part: {inspectionData.carpart}
                        </Text>
                    </Row>
                    <Row style={{marginTop: 4}}>
                        <Text style={{marginLeft: 0}}>
                            Damage: {inspectionData.damage_type}
                        </Text>
                    </Row>
                    <Row style={{paddingRight: 24, marginTop: 4}}>
                        {inspectionData?.inspection_result?.length > 150 ?
                            <Tooltip
                                title={inspectionData?.inspection_result}
                                mouseEnterDelay={0.5}  // Show after 0.5 seconds
                                mouseLeaveDelay={0}    // Hide immediately>
                            >
                                <Text>
                                    {'Comment: ' + (inspectionData?.inspection_result ? (
                                        inspectionData?.inspection_result.length > 150 ?
                                            inspectionData?.inspection_result.substring(0, 150) + '...' :
                                            inspectionData?.inspection_result) : 'None')}
                                </Text>
                            </Tooltip>
                            :
                            <Text>
                                {'Comment: ' + inspectionData?.inspection_result}
                            </Text>
                        }
                    </Row>
                    <Row style={{paddingLeft: 100, paddingRight: 100, marginTop: 16}}>
                        <CarPartRenderer value={inspectionData?.carPart_id}/>
                    </Row>
                </div>
            }
            {!inspectionData === 0 &&
                <Row style={{
                    marginTop: 100,
                    marginBottom: 196,
                    marginLeft: 150
                }}>
                    No data available
                </Row>
            }
        </Card>
    )
};

export default InspectionInfo;