import React, {useEffect, useState} from 'react';
import {
    InputNumber,
    notification,
    Select,
    Table,
    Row,
    Col,
    Typography,
    Radio,
    Descriptions,
    Button,
    Tooltip,
    Checkbox,
    Popover, Divider, Alert
} from 'antd';
import axiosInstance from "../../services/axios";
import TagRenderer from "../TagRendererV2/TagRendererV2";
import TagValues from "../TagValues/TagValues";
import CommentTags from "./CommentTags";
import {EditFilled} from "@ant-design/icons";

const {Option} = Select;

const {Text, Paragraph, Link} = Typography;


const ImageLabels = (props) => {

    // console.log(props)

    const [labelsExist, setLabelsExits] = useState(false)
    const [labelData, setLabelData] = useState({
        n_images: null,
        zoom: null,
        angle: null,
        reflections: null,
        objects: null,
    });

    useEffect(() => {
        setLabelsExits(false)
        setLabelData({
            n_images: null,
            zoom: null,
            angle: null,
            reflections: null,
            objects: null,
        })

    }, [props.searchValue]);

    useEffect(() => {
        if (props.labelData && props.labelData.id) {
            setLabelsExits(true)
            setLabelData(
                {
                    id: props.labelData.id,
                    n_images: props.labelData.n_images,
                    zoom: props.labelData.zoom,
                    angle: props.labelData.angle,
                    reflections: props.labelData.reflections,
                    objects: props.labelData.objects,
                }
            )
        } else if (props.feedbackImages) {
            setLabelsExits(false)
            setLabelData(
                {
                    n_images: props.feedbackImages.length,
                    zoom: null,
                    angle: null,
                    reflections: null,
                    objects: null,
                }
            )
        } else {
            setLabelsExits(false)
            setLabelData(
                {
                    n_images: null,
                    zoom: null,
                    angle: null,
                    reflections: null,
                    objects: null,
                }
            )
        }
    }, [props.labelData, props.feedbackImages, props.labelTarget]);

    const zoomDropdown = [
        {
            'value': 'both',
            'label': 'both',
        },
        {
            'value': 'distance',
            'label': 'distance',
        },
        {
            'value': 'close-up',
            'label': 'close-up',
        }
    ]

    const angleDropdown = [
        {
            'value': 'poor',
            'label': 'poor',
        },
        {
            'value': 'ok',
            'label': 'ok',
        },
        {
            'value': 'ideal',
            'label': 'ideal',
        }
    ]

    const objectsDropdown = [
        {
            'value': 'none',
            'label': 'none',
        },
        {
            'value': 'object',
            'label': 'object',
        },
        {
            'value': 'rain',
            'label': 'rain',
        },
        {
            'value': 'snow',
            'label': 'snow',
        },
        {
            'value': 'dirt',
            'label': 'dirt',
        },
        {
            'value': 'leafs',
            'label': 'leafs',
        }
    ]

    const handleUpdate = (value, key) => {
        setLabelData(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };

    const onUpdateComment = (newComment) => {
        setLabelData(prevState => ({
            ...prevState,
            comment: newComment,
        }));
    };

    const onConfirmLabels = () => {
        console.log(labelData)
        const allNonNull = Object.values(labelData).every(value => value !== null);

        if (allNonNull) {
            axiosInstance.post(
                '/api/image_tool/labels/images_update',
                {
                    update: labelsExist,
                    customer: props.customer,
                    search_value: props.searchValue,
                    search_type: props.searchType,
                    label_type: 'images',
                    update_dict: labelData,
                    vehicle_uuid: props.vehicleUUID
                })
                .then(res => {
                    notification['success']({
                        message: 'Image Labels updated',
                    });
                    props.setUpdateCounter(prevCounter => prevCounter + 1);
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({
                        message: 'Error in updateLabels',
                        description: (
                            <>
                                {error.message + ':'}<br/>
                                {error.response && error.response.data && error.response.data.message}
                            </>
                        ),
                    });
                })
        } else {
            notification['error']({
                message: 'Not all labels have a value',
            });
        }
    };

    return (
        <div>
            <Row>
                <Descriptions bordered size={'small'} column={2} style={{marginBottom: 16}}>
                    <Descriptions.Item label="# Images" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <InputNumber
                            onChange={(value) => handleUpdate(value, 'n_images')}
                            size={'small'}
                            min={1} max={99}
                            value={labelData?.n_images}
                            style={{width: 75}}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Zoom" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {zoomDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'} value={labelData?.zoom}
                                buttonStyle="solid"
                                onChange={(e) => handleUpdate(e.target.value, 'zoom')}>
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Angle" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        {angleDropdown.map(option => (
                            <Radio.Group
                                key={option.value} optionType="button" size={'small'} value={labelData?.angle}
                                buttonStyle="solid"
                                onChange={(e) => handleUpdate(e.target.value, 'angle')}>
                                <Radio value={option.value}>{option.label}</Radio>
                            </Radio.Group>
                        ))}
                    </Descriptions.Item>
                    <Descriptions.Item label="Reflections" style={{height: '10px', padding: 4, paddingLeft: 16}}>
                        <Radio.Group
                            optionType="button" size={'small'} value={labelData?.reflections} buttonStyle="solid"
                            onChange={(e) => handleUpdate(e.target.value, 'reflections')}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </Descriptions.Item>
                    <Descriptions.Item label="Objects">
                        <Checkbox.Group
                            value={labelData?.objects}
                            onChange={(checkedValues) => handleUpdate(checkedValues, 'objects')}
                        >
                            {objectsDropdown.map(option => (
                                <Checkbox key={option.value} value={option.value}>
                                    {option.label}
                                </Checkbox>
                            ))}
                        </Checkbox.Group>
                    </Descriptions.Item>
                </Descriptions>
            </Row>
            <Row>
                <Button onClick={onConfirmLabels} type={'primary'} style={{marginBottom: 8}}>
                    {labelsExist ? 'Update' : 'Submit'}
                </Button>
                {/*<Button onClick={onConfirmLabels} type={'primary'} disabled={true} style={{marginBottom: 8}}>*/}
                {/*    Confirm*/}
                {/*</Button>*/}
                {labelsExist ? (
                    <div style={{marginLeft: 16}}>
                        <CommentTags
                            data={labelData?.comment}
                            id={labelData?.id}
                            customer={props.customer}
                            labelType={'images'}
                            labelTarget={props.labelTarget}
                            onUpdateComment={onUpdateComment}
                        />
                    </div>
                ) : (
                    <Tooltip title="Submit a label to comment">
                        <div style={{opacity: 0.5, width: 150, pointerEvents: 'none', marginLeft: 16}}>
                            <CommentTags/>
                        </div>
                    </Tooltip>
                )}
            </Row>
        </div>
    );
};

export default ImageLabels;