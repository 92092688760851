import React, {useState, useRef, useEffect, useCallback, useMemo} from 'react';
import 'ag-grid-enterprise';
import {AgGridReact} from 'ag-grid-react';
import {LicenseManager} from 'ag-grid-enterprise';
import moment from 'moment';
import dayjs from 'dayjs';

import {
    DatePicker, Button, Card, Select, Col, notification, Row, Dropdown, Space, Popover, Menu,
    Typography, Checkbox, Radio, InputNumber, Switch, Modal, Spin, Tooltip
} from 'antd';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import Icon, {DownloadOutlined, SettingOutlined} from "@ant-design/icons";
import {FaChartBar} from 'react-icons/fa';
import axiosInstance from "services/axios";

import {getColumnDefs} from "./MonitoringDashboardColumns";
import FilterForm from "../MapTool/FilterForm";
import ViewManager from "./ViewManager";

LicenseManager.setLicenseKey("CompanyName=carValoo GmbH,LicensedGroup=carValoo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-038534,SupportServicesEnd=21_February_2024_[v2]_MTcwODQ3MzYwMDAwMA==569a60f2ef339ea47d5835a5fc039c3f")

const {Text} = Typography;
const {Option} = Select;
const {RangePicker} = DatePicker;

const MonitoringDashboard = (props) => {

    const gridRef = useRef();

    useEffect(() => {
        document.title = 'Monitoring Tool';
    }, []);

    const [customerData, setCustomerData] = useState(null);
    const [reverseCustomerData, setReverseCustomerData] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeTrendsActive, setTimeTrendsActive] = useState(false);
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterActive, setFilterActive] = useState(false);
    const [timeTrends, setTimeTrends] = useState(false);
    const [filterExcluded, setFilterExcluded] = useState(true);
    const [filterValues, setFilterValues] = useState([]);
    const [rowGroupBy, setRowGroupBy] = useState('customer');
    const [minimumDriving, setMinimumDriving] = useState(1);
    const [periodTimeTrends, setPeriodTimeTrends] = useState(30);
    const [queryStartDate, setQueryStartDate] = useState(moment().subtract(6, 'months').format('YYYY-MM-DD'));
    const [queryEndDate, setQueryEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [filterModel, setFilterModel] = useState({});
    const [isInitRun, setIsInitRun] = useState(true)
    const [columnDefs, setColumnDefs] = useState(getColumnDefs())
    const [viewId, setViewId] = useState(null)
    const [showStyle, setShowStyle] = useState(false)
    const [showTrend, setShowTrend] = useState(false)
    const [trendStartDate, setTrendStartDate] = useState(null);
    const [trendEndDate, setTrendEndDate] = useState(null);

    const rowSelection = useMemo(() => {
        return {mode: "singleRow", checkboxes: false, enableClickSelection: true};
    }, []);

    const rangePresets = [
        {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
        {label: 'Last 60 Days', value: [dayjs().add(-60, 'd'), dayjs()]},
        {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
        {label: 'Last 180 Days', value: [dayjs().add(-180, 'd'), dayjs()]},
        {label: 'Last 365 Days', value: [dayjs().add(-365, 'd'), dayjs()]},
    ];

    const startDate = dayjs(queryStartDate);
    const rangePresetsTrend = [
        {label: 'Previous 30 Days', value: [startDate.add(-30, 'd'), startDate]},
        {label: 'Previous 60 Days', value: [startDate.add(-60, 'd'), startDate]},
        {label: 'Previous 90 Days', value: [startDate.add(-90, 'd'), startDate]},
        {label: 'Previous 180 Days', value: [startDate.add(-180, 'd'), startDate]},
        {label: 'Previous 365 Days', value: [startDate.add(-365, 'd'), startDate]},
    ];

    useEffect(() => {
        const getParams = new URLSearchParams(window.location.search);
        const viewId = parseInt(getParams.get('view_id'), 10);
        setViewId(isNaN(viewId) ? null : viewId);
    }, []);

    useEffect(() => {

        const getGridData = () => {
            return new Promise((resolve, reject) => {

                // Send request to python backend
                const payload = {
                    filter_date: {
                        start_date: queryStartDate,
                        end_date: queryEndDate,
                    },
                    filter_excluded: filterExcluded,
                    row_groupby: rowGroupBy,
                    time_trends: timeTrends,
                    period_time_trends: periodTimeTrends,
                    minimum_driving_time: minimumDriving,
                    filter_values: filterValues,
                    trend_start: trendStartDate,
                    trend_end: trendEndDate
                };
                console.log(payload);

                setLoading(true)

                axiosInstance.post('/api/monitoring/kpi', payload)
                    .then(res => {
                        // Set row data based on JSON response
                        console.log(res.data)
                        setLoading(false)
                        setCustomerData(res.data.customers)

                        setFilterModel(gridRef.current.api.getFilterModel())

                        resolve(res.data.data);

                        const reverseMapping = {};
                        for (let key in res.data.customers) {
                            const customer = res.data.customers[key];
                            reverseMapping[customer.name] = customer.identifier;
                        }
                        console.log(reverseMapping)
                        setReverseCustomerData(reverseMapping)
                    })
                    .catch(error => {
                        setLoading(false)
                        console.log(error);
                        notification['error']({
                            message: 'Error retrieving monitoring data',
                            description: error.message,
                            duration: 5,
                        });
                        reject(error);
                    });
            });
        };

        // Get the grid data
        getGridData().then((rowData) => {
            // Apply the column defs
            if (columnDefs) {
                gridRef.current.api.setGridOption('columnDefs', columnDefs);
            }
            setRowData(rowData);
            gridRef.current.api.setGridOption('rowData', rowData)

            console.log('FINISHED LOADING DATA')
        });

    }, [trendEndDate, trendStartDate, queryStartDate, queryEndDate, filterExcluded, rowGroupBy, timeTrends, periodTimeTrends, minimumDriving, filterValues]);

    useEffect(() => {
        if (filterModel && gridRef?.current?.api && !isInitRun) {
            gridRef.current.api.setFilterModel(filterModel);
        }
    }, [filterModel]);

    useEffect(() => {
        if (gridRef?.current?.api && isInitRun) {

            // Initialize an empty array to store customer values
            const customersDefault = [];

            // Iterate through the array
            for (const obj of rowData) {
                // Check if the 'show_default' key is true
                if (obj.show_default === true) {
                    // If true, append the 'customer' value to the new array
                    customersDefault.push(obj.customer);
                }
            }

            const tempFilterModel = {
                "customer": {
                    "values": customersDefault,
                    "filterType": "set"
                }
            }

            setFilterModel(tempFilterModel)
            gridRef.current.api.setFilterModel(tempFilterModel);
            setIsInitRun(false)
        }
    }, [isInitRun, rowData, gridRef]);

    const onResetColumns = useCallback(() => {
        gridRef.current.api.resetColumnState();
        console.log('column state reset');
    }, []);

    const handleFilterChange = (values) => {
        if (filterOpen) {
            setFilterOpen(false);
        }
        console.log('Received values of form:', values)

        if (values?.filters?.length > 0) {
            setFilterActive(true)
            setFilterValues(values.filters)
        } else {
            setFilterActive(false)
            setFilterValues([])
        }
    };

    const handleFilterOpen = () => {
        setFilterOpen(!filterOpen);
    };

    const onChangeExcludeStats = (value) => {
        setFilterExcluded(value.target.checked)
    }

    const onChangeDateRange = (value) => {
        if (value) {
            setQueryStartDate(value[0].format('YYYY-MM-DD'))
            setQueryEndDate(value[1].format('YYYY-MM-DD'))
        } else {
            setQueryStartDate("2000-01-01")
            setQueryEndDate("2100-01-01")
        }
    }

    const onChangeTrendDateRange = (value) => {
        if (value) {
            setTrendStartDate(value[0].format('YYYY-MM-DD'))
            setTrendEndDate(value[1].format('YYYY-MM-DD'))
        } else {
            setTrendStartDate(null)
            setTrendEndDate(null)
        }
    }

    const onChangeMinimumDriving = (value) => {
        if (value) {
            setMinimumDriving(value)
        }
    }

    function deactivateGroupView(columnDefs) {
        const columnHeaders = ["Vehicles", "Report"]
        const toggleColumns = [
            "status", "phase", "comment", "ratio", "defined_action", "status_action", "show_default", "created_datetime",
            "car_count", "van_count", "car_percentage", "van_percentage", "alert_configuration"
        ];

        columnDefs.forEach(function (colDef, index) {
            if (colDef.field === 'customer') {
                colDef.rowGroup = false;
                colDef.hide = false;
            }
            if (colDef.field === 'group') {
                colDef.hide = true;
            }

            if (columnHeaders.includes(colDef.headerName)) {
                colDef.children.forEach(function (child, index) {
                    if (toggleColumns.includes(child.field)) {
                        child.hide = false;
                    }
                });
            }

        });
        return columnDefs;
    }

    function activateGroupView(columnDefs, value) {
        const columnHeaders = ["Vehicles", "Report"]
        const toggleColumns = [
            "status", "phase", "comment", "ratio", "defined_action", "status_action", "show_default", "created_datetime",
            "car_count", "van_count", "car_percentage", "van_percentage", "alert_configuration"
        ];

        columnDefs.forEach(function (colDef, index) {
            if (colDef.field === 'customer') {
                colDef.rowGroup = true;
                colDef.hide = true;
            }
            if (colDef.field === 'group') {
                colDef.width = 150
                if (value === 'vehicle_type') {
                    colDef.width = 250
                }

                const valueToLabelMap = {
                    customer: 'Customer',
                    vehicle_category: 'Category',
                    vehicle_class_category: 'Class',
                    vehicle_type: 'Type',
                    engine_type: 'Engine',
                    fleet: 'Fleet',
                    station: 'Station',
                };

                colDef.headerName = valueToLabelMap[value];
                colDef.hide = false;
            }

            if (columnHeaders.includes(colDef.headerName)) {
                colDef.children.forEach(function (child, index) {
                    if (toggleColumns.includes(child.field)) {
                        child.hide = true;
                    }
                });
            }

        });
        return columnDefs;
    }

    const onChangeGroupView = (value) => {
        let columnDefsNew = getColumnDefs();

        setTimeTrendsActive(false)
        setTimeTrends(false)

        const group = value.target?.value || value

        if (group === 'customer') {
            columnDefsNew = deactivateGroupView(columnDefs)
            setRowGroupBy('customer')
            console.log('Deactivated group view');
        } else {
            columnDefsNew = activateGroupView(columnDefs, group)
            setRowGroupBy(group)
            console.log('Activated group view');
        }
        setColumnDefs(columnDefsNew)
    };

    const onExportMenuClick = (e) => {

        let data = {
            fileType: e.key,
            filter_date: {
                start_date: queryStartDate,
                end_date: queryEndDate,
            },
        };

        axiosInstance.post('/api/monitoring/export', data, {responseType: 'blob'})
            .then(res => {
                // create file link in browser's memory
                console.log(res)
                const href = URL.createObjectURL(res.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                let fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];

                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
            .catch(error => {
                console.log(error);
                notification['error']({
                    message: 'Error in exporting data',
                    description: (
                        <>
                            {error.message + ':'}<br/>
                            {error.response && error.response.data && error.response.data.message}
                        </>
                    ),
                });
            })

    }

    const exportMenuProps = {
        items: [
            {
                label: 'Alerts To Excel',
                key: 'xlsx',
            },
            {
                label: 'Alerts To CSV',
                key: 'csv',
            },
        ],
        onClick: onExportMenuClick,
    };

    const onCellValueChanged = (params) => {
        console.log(params);

        let col = params.column.getColId();
        if (params.oldValue !== params.newValue) {
            let data = {
                customer: params.context.reverseCustomerData[params.data.customer],
                status: params.data.status,
                phase: params.data.phase,
                comment: params.data.comment,
                defined_action: params.data.defined_action,
                status_action: params.data.status_action,
                show_default: params.data.show_default,
            };
            data[col] = params.newValue

            axiosInstance.post('/api/monitoring/customer', data)
                .then(response => {
                    //params.success(response.values);
                    console.log('Success adding new entry');
                    console.log(response);
                    console.log(params);
                    notification['success']({
                            message: 'Updated project report for ' + params.data.customer,
                            duration: 2.5,
                            maxCount: 15
                        }
                    );
                })
                .catch(error => {
                    console.log(error);
                    notification['error']({message: 'Error updating', description: error.message});
                })
        }
    }

    function verticalCellAlign(params) {
        return {
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center'
        };
    }

    function alphabeticallyComparator(valueA, valueB) {
        // Check if either value is equal to "apple"
        if (valueA === 'carvaloo') {
            return -1; // valueA should appear before valueB
        } else if (valueB === 'carvaloo') {
            return 1; // valueB should appear before valueA
        }

        // Sort alphabetically for other values
        if (valueA === valueB) {
            return 0;
        } else if (valueA < valueB) {
            return -1;
        } else {
            return 1;
        }
    }

    const onResetAllFilters = () => {
        gridRef.current.api.setFilterModel(null);
    }

    const gridOptions = {
        localeText: {
            noRowsToShow: 'Loading data ...',
        },
        rowHeight: 50,
        cellSelection: 'true',
        groupDisplayType: 'multiple',
        groupDefaultExpanded: 999,
        groupRowRendererParams: {
            suppressCount: true
        },
        suppressAggFuncInHeader: true,
        getRowStyle: function (params) {
            if (params.data && params.data.customer && params.data.customer === 'carvaloo') {
                return {
                    // background: 'rgba(10,145,220,0.3)', // Set your desired background color for pinned row
                    fontWeight: 'bold' // Set your desired font weight for pinned row
                };
            }
            return null;
        },
        defaultColDef: {
            cellDataType: false,
            width: 100,
            autoHeight: true,
            sortable: true,
            filter: false,
            resizable: true, //minWidth: 100,
            editable: false, //cellEditor: PopupCellEditor
            suppressHeaderMenuButton: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: verticalCellAlign,
            comparator: alphabeticallyComparator
        },
        sideBar: {
            defaultToolPanel: '',
            position: 'right',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    minWidth: 225,
                    maxWidth: 400,
                    width: 300,
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivotMode: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                    minWidth: 180,
                    maxWidth: 400,
                    width: 250
                }
            ],
        },
        defaultExcelExportParams: {
            processCellCallback: function (params) {
                if (params.column.colDef && params.column.colDef.cellClass === 'dateType') {
                    if (params.value) {
                        return moment(params.value).format('YYYY-MM-DDThh:mm:ss');
                    } else {
                        return params.value;
                    }
                } else {
                    return params.value;
                }
                //debugger;
            }
        },
        excelStyles: [
            {
                id: 'dateType',
                dataType: 'DateTime',
                numberFormat: {
                    format: 'yyy-mm-ddThh:mm:ss'
                }
            }
        ],
        onGridReady: function () {
            console.log('grid ready');
        },
        onCellValueChanged: onCellValueChanged,
        statusBar: {
            statusPanels: [
                {statusPanel: 'agAggregationComponent', align: 'left'},
            ],
        }
    }


    const settingsMenu = (
        <div style={{width: 350, height: 200}}>
            {/*<Row>*/}
            {/*    <Col>*/}
            {/*        <span>Time trend size (days): </span>*/}
            {/*        <InputNumber*/}
            {/*            min={7}*/}
            {/*            max={60}*/}
            {/*            defaultValue={30}*/}
            {/*            style={{width: '70px'}}*/}
            {/*            onChange={onPeriodChange}*/}
            {/*            size={'small'}*/}
            {/*        />*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Row style={{marginBottom: 8}}>
                <Checkbox
                    onChange={onChangeExcludeStats}
                    defaultChecked={true}
                    style={{verticalAlign: 'middle'}}
                >
                    Special Events Excluded
                </Checkbox>
            </Row>
            <Row style={{marginBottom: 8}}>
                <Col>
                    <span>Minimum driving time (minutes): </span>
                    <InputNumber
                        min={1}
                        max={1800}
                        defaultValue={1}
                        style={{width: '70px'}}
                        onChange={onChangeMinimumDriving}
                        size={'small'}
                    />
                </Col>
            </Row>
            <Row style={{marginBottom: 8}}>
                <span>Trend Range: </span>
                <RangePicker
                    style={{marginLeft: 8, width: 250}}
                    size={'small'}
                    // open={isOpen}
                    // onOpenChange={(open) => setIsOpen(open)}
                    // onSelect={handleSelect}
                    defaultValue={[null, null]}
                    defaultPickerValue={[null, null]}
                    presets={rangePresetsTrend}
                    onChange={onChangeTrendDateRange}
                />
            </Row>
        </div>
    );


    const filterOptions = [
        {
            label: 'Confidence Model',
            value: 'model_confidence',
            example: 'example: wavenet__2.0'
        },
        {
            label: 'Severity Model',
            value: 'model_severity',
            example: 'example: severity__6a'
        },
        {
            label: 'Confidence Score',
            value: 'alert_confidence',
            example: 'example: high'
        },
        {
            label: 'Severity Score',
            value: 'alert_severity',
            example: 'example: medium'
        },
        {
            label: 'UAIDs',
            value: 'uaids',
            example: 'example: UAID-20001207-240228135141, UAID-20001216-240227141635'
        }
    ]

    const filterSelectOptions = [
        {
            label: 'equals',
            value: 'equals',
            tooltipContent: 'Equals - include exact matches',
        },
        {
            label: 'not equals',
            value: 'not_equals',
            tooltipContent: 'Not Equals - exclude exact matches',
        },
        {
            label: 'smaller',
            value: 'smaller',
            tooltipContent: 'Smaller Than - set an upper limit',
        },
        {
            label: 'greater',
            value: 'greater',
            tooltipContent: 'Greater Than - set a lower limit',
        },
        {
            label: 'contains',
            value: 'contains',
            tooltipContent: 'Contains - include partial matches',
        },
        {
            label: 'not contains',
            value: 'not_contains',
            tooltipContent: 'Not Contains - exclude partial matches',
        },
        // {
        //     label: 'subset',
        //     value: 'subset',
        //     tooltipContent: 'Subset - selected field is in list',
        // },
    ];

    const onToggleThresholds = () => {
        gridRef.current.api.redrawRows();
        setShowStyle(!showStyle)
    }

    const onToggleTrends = () => {
        gridRef.current.api.redrawRows();
        setShowTrend(!showTrend)
        if (!showTrend) {
            setTimeTrends(true)
        }
    }

    return (<>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8'
                }}
                styles={{body: {padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"}}}
            >
                <Row gutter={16} style={{marginBottom: "8px"}}>
                    <Col span={24}>
                        <Card styles={{body: {padding: '4px'}}}>
                            <Row gutter={[16, 16]}>
                                {/*<Col>*/}
                                {/*    <Button onClick={onRefreshData}>Refresh data</Button>*/}
                                {/*</Col>*/}
                                <Col>
                                    <Button onClick={onResetAllFilters}>Reset all filters</Button>
                                </Col>
                                <Col>
                                    <Button onClick={onResetColumns}>Reset columns</Button>
                                </Col>
                                <Col>
                                    <RangePicker
                                        // open={isOpen}
                                        // onOpenChange={(open) => setIsOpen(open)}
                                        // onSelect={handleSelect}
                                        defaultValue={[dayjs(queryStartDate), dayjs(queryEndDate)]}
                                        defaultPickerValue={[dayjs(queryStartDate), dayjs(queryEndDate)]}
                                        presets={rangePresets}
                                        onChange={onChangeDateRange}
                                    />
                                </Col>
                                <Col>
                                    <Radio.Group
                                        options={[
                                            {label: 'Customer', value: 'customer'},
                                            {label: 'Category', value: 'vehicle_category'},
                                            {label: 'Class', value: 'vehicle_class_category'},
                                            {label: 'Type', value: 'vehicle_type'},
                                            {label: 'Engine', value: 'engine_type'},
                                            {label: 'Fleet', value: 'fleet'},
                                            {label: 'Station', value: 'station'},
                                        ]}
                                        onChange={onChangeGroupView}
                                        value={rowGroupBy}
                                        optionType="button"/>
                                </Col>
                                {/*<Col style={{display: 'flex', justifyContent: 'flex-end', marginTop: '5px'}}>*/}
                                {/*    <Tooltip title={'Temporarily disabled'}>*/}
                                {/*        <Switch*/}
                                {/*            //style={{marginTop: '5px'}}*/}
                                {/*            onChange={onChangeTimeTrends}*/}
                                {/*            checkedChildren="trends"*/}
                                {/*            unCheckedChildren="trends"*/}
                                {/*            checked={timeTrendsActive}*/}
                                {/*            disabled={true}*/}
                                {/*        />*/}
                                {/*    </Tooltip>*/}
                                {/*</Col>*/}
                                <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Popover
                                        trigger="click"
                                        placement="bottom"
                                        content={
                                            <FilterForm
                                                onFinish={handleFilterChange} fieldOptions={filterOptions}
                                                conditionOptions={filterSelectOptions}
                                            >
                                            </FilterForm>}
                                        open={filterOpen}
                                    >
                                        <Button
                                            onClick={handleFilterOpen}
                                            style={{backgroundColor: filterActive ? "lightcoral" : "white"}}
                                        >
                                            Filter alerts
                                        </Button>
                                    </Popover>
                                </Col>
                                <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <ViewManager
                                        gridRef={gridRef}
                                        viewId={viewId}
                                        setFilterModel={setFilterModel}
                                        queryStartDate={queryStartDate}
                                        queryEndDate={queryEndDate}
                                        filterValues={filterValues}
                                        rowGroupBy={rowGroupBy}
                                        setQueryStartDate={setQueryStartDate}
                                        setQueryEndDate={setQueryEndDate}
                                        onChangeGroupView={onChangeGroupView}
                                        handleFilterChange={handleFilterChange}
                                    />
                                </Col>
                                <Col style={{paddingTop: 4}}>
                                    <Text>
                                        Thresholds
                                    </Text>
                                    <Switch
                                        style={{marginLeft: 8}}
                                        onChange={onToggleThresholds}
                                        checked={showStyle}
                                    />
                                </Col>
                                <Col style={{paddingTop: 4}}>
                                    <Text>
                                        Trends
                                    </Text>
                                    <Switch
                                        style={{marginLeft: 8}}
                                        onChange={onToggleTrends}
                                        checked={showTrend}
                                    />
                                </Col>
                                <Col>
                                    <Dropdown menu={exportMenuProps}>
                                        <Button>
                                            <DownloadOutlined/>
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Popover
                                        title={'Settings'}
                                        trigger="click"
                                        content={settingsMenu}
                                    >
                                        <Button>
                                            <Space>
                                                Settings
                                                <SettingOutlined/>
                                            </Space>
                                        </Button>
                                    </Popover>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className="ag-theme-alpine" style={{flex: "1 1 auto"}}>
                    <Col style={{flex: "1 1 auto"}}>
                        <AgGridReact
                            // debug={true}
                            loading={loading}
                            ref={gridRef}
                            rowSelection={rowSelection}
                            gridOptions={gridOptions}
                            columnDefs={columnDefs}
                            context={{
                                showStyle: showStyle,
                                showTrend: showTrend,
                                customerData: customerData,
                                reverseCustomerData: reverseCustomerData
                            }}
                            autoGroupColumnDef={{
                                headerName: 'Customer',
                                minWidth: 180,
                                cellRendererParams: {
                                    suppressCount: true,
                                },
                                cellStyle: {textAlign: 'left', paddingTop: '10px'}, // Aligns text to the left,
                                pinned: 'left',
                                filter: 'agGroupColumnFilter',
                                floatingFilter: true,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </>

    );
}

export default MonitoringDashboard;
