import React, {useState, useRef, useCallback, useEffect} from 'react';

import {
    Button,
    Card,
    Carousel,
    Descriptions,
    Select,
    Col,
    Modal,
    List,
    notification,
    Row,
    Typography,
    Input,
    Spin,
    Switch,
    Table,
    Timeline,
    Divider,
    Popover
} from 'antd';

import Icon, {BorderInnerOutlined, EditFilled} from "@ant-design/icons";
import axiosInstance from "services/axios";
import ChartToolModal from "../ChartTool/ChartToolModal";
import ReactJson from "react-json-view";
import {FaCog, FaFile, FaList, FaRedo} from "react-icons/fa";
import TagValues from "../TagValues/TagValues.js"
import HistoryModal from "../DetailsCard/HistoryModal";
import ImageRenderer from "../ImageRenderer/ImageRenderer";
import CarPartRenderer from "../CarPartRenderer/CarPartRenderer";

const {Title} = Typography;
const {Text} = Typography;


const AnomalyReservationCard = (props) => {

    const UAID = props.UAID
    const [data, setData] = useState(null);
    const [noData, setNoData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inputNumber, setInputNumber] = useState('9');
    const [inputDatabase, setInputDatabase] = useState('anomaly-similarity');

    function getIncidentData(payload, showLoading) {
        // Send request to python backend

        if (showLoading) {
            setLoading(true);
        }
        axiosInstance.post('/api/anomaly_tool/incident', payload)
            .then(res => {
                if (showLoading) {
                    setLoading(false);
                }
                // Set row data based on JSON response
                if (res.data.data.length === 0) {
                    setNoData(true)
                }

                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                // res.data.data.push(res.data.data[0])
                setData(res.data.data)
            })
            .catch(error => {
                if (showLoading) {
                    setLoading(false);
                }
                console.log(error);
                notification['error']({
                        message: error.message,
                        description: error.response.data.message,
                        duration: 10,
                    }
                );
            })
    }

    useEffect(() => {
        if (UAID !== null) {
            const payload = {
                UAID: UAID,
            };

            getIncidentData(payload, true);
        } else {
            setData(null)
        }
    }, [UAID, props.reload]);

    return (
        <>
            <Card
                style={{
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
                    border: '1px solid #e8e8e8',
                    minHeight: 400,
                }}
                title={'Reservations'}
                styles={{
                    body: {
                        padding: '5px', display: 'flex', flexDirection: "column", flex: "1 1 auto"
                    }
                }}
            >
                {loading &&
                    <div className="example" style={{marginLeft: 8}}>
                        <Spin/>
                    </div>
                }
                {data &&
                    <div style={{maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden'}}>
                        <Timeline style={{marginTop: 20, marginLeft: 20}}>
                            {data.map((item) => (
                                <Timeline.Item
                                    key={item.event_id}
                                    // label={item.Vorfalldatum.replace('T', ' - ').slice(0, 21)}
                                >
                                    <Row>

                                        <Col span={14}>
                                            <Row>
                                                {item.Vorfalldatum.replace('T', ' - ').slice(0, 21)}
                                                <Popover
                                                    title={'Comment'}
                                                    trigger="click"
                                                    content={
                                                        <Text>
                                                            {item.Comment}
                                                        </Text>
                                                    }
                                                    placement={'right'}
                                                >
                                                    <Button size={"small"} icon={<EditFilled/>}
                                                            style={{marginLeft: 10}}>
                                                        View comment
                                                    </Button>
                                                </Popover>
                                            </Row>
                                            EventId: {item.event_id} - {item.gemeldetVon}
                                            <Text>
                                                {item.Offerte ? ' - Offer: ' + item.Offerte : ' - Offer: N/A'}
                                            </Text>
                                        </Col>
                                        <Col span={6} style={{marginTop: -16, marginBottom: -16}}>
                                            <CarPartRenderer value={item?.car_part_ids}/>
                                        </Col>
                                    </Row>
                                    <Divider style={{marginTop: 4, marginBottom: 4}}></Divider>
                                    {/*<Text style={{color: item.location.lat ? 'black' : 'red'}}>*/}
                                    {/*    Latitude: {item.location.lat ? item.location.lat : 'Not available'}*/}
                                    {/*</Text>*/}
                                    {/*<Divider type={'vertical'} style={{marginTop: 4, marginBottom: 4}}></Divider>*/}
                                    <Row>
                                        <Text style={{marginRight: 4}}>
                                            {item.VorfallBeurteilung ? 'Process: ' + item.VorfallBeurteilung : 'Process: N/A'}
                                        </Text>
                                        <Text>
                                            {item.Schadenort ? '  - Location: ' + item.Schadenort : '  - Location: N/A'}
                                        </Text>
                                    </Row>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </div>
                }
                {noData && <div style={{marginLeft: 16}}> No incidents available</div>}
            </Card>
        </>
    );
}

export default AnomalyReservationCard;
